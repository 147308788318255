import { FormInstance } from "antd";
import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { Project } from "../types/Project";
import { FieldData } from "rc-field-form/lib/interface";

type Props = {
  form: FormInstance<Omit<Project, "pic"> & { picId: string }>;
  modalInfo: Project;
  setButtonDisabled: Dispatch<SetStateAction<boolean>>;
};

const useUpdateProjectModal = ({
  form,
  modalInfo,
  setButtonDisabled,
}: Props) => {
  const submitButtonRef = useRef<HTMLInputElement>(null);
  const trackingChangesRef = useRef<{[key: string]: boolean}>({});

  const onFieldsChange = (changedFields: FieldData[]) => {
    const { name, value } = changedFields[0];
    const changedFieldName = name;
    const changedValue = value;
    const changedKey =
      changedFieldName instanceof Array ? changedFieldName[0] : "";
    const lastValue =
      changedKey === "picId"
        ? modalInfo["pic"].id
        : modalInfo[changedKey as keyof Project];
    const trackingChanges = trackingChangesRef.current;
    trackingChanges[changedKey] = changedValue !== lastValue;
    trackingChangesRef.current = trackingChanges;
    const hasChanges = Object.keys(trackingChanges).some((key) => trackingChanges[key]);
    const isError = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    setButtonDisabled(!hasChanges || isError);
  };

  const onModalOk = () => {
    submitButtonRef.current?.click();
  };

  const clearTrackingChanges = useCallback(() => {
    trackingChangesRef.current = {};
    setButtonDisabled(true);
  }, [setButtonDisabled]);

  return { submitButtonRef, onFieldsChange, onModalOk, clearTrackingChanges };
};

export default useUpdateProjectModal;
