import { InputRef } from "antd";
import { useRef, useState } from "react";

const useAddContributorModal = () => {
  const submitRef = useRef<HTMLInputElement>(null);
  const inputSearchRef = useRef<InputRef>(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  const onModalOk = () => {
    submitRef.current?.click();
  };

  const onSearchBarFocus = () => {
    if (inputSearchRef.current?.input?.value) {
      setOpenDropdown(true);
    }
  };

  return {
    submitRef,
    inputSearchRef,
    openDropdown,
    setOpenDropdown,
    onModalOk,
    onSearchBarFocus,
  };
};

export default useAddContributorModal;
