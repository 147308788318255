import { Alert } from "antd";
import React from "react";
import styles from "./CurrentPrutBanner.module.css";
import { DateHelper } from "../helpers/DateHelper";
import { Weekday } from "../enum/Weekday";

interface PreviousPrutBannerProps {
  prut: {
    currentWeekNumber: number;
    currentWeekYear: number;
    currentWeekStartDate: string;
    currentWeekEndDate: string;
    previousWeekStartDate?: string;
    previousWeekEndDate?: string;
    previousWeekNumber: number;
    previousWeekYear: number;
  };
}

const PreviousPrutBanner: React.FC<PreviousPrutBannerProps> = ({ prut }) => {
  const mondayOfWeek = new Date(prut.previousWeekStartDate ?? '');
  const nextSundayOfCurrentWeek = DateHelper.getNextSundayInDate(new Date(prut.currentWeekStartDate));
  const currentWeekRange = DateHelper.getCurrentWeekRange(mondayOfWeek);
  const lastEditableDate = DateHelper.getWeekday(
    nextSundayOfCurrentWeek,
    Weekday.WED
  );

  return (
    <Alert
      message={
        <ul className={styles.list}>
          <li>
            <b>Previous PRUT</b> shown is for{" "}
            <b>{`Week ${DateHelper.formatWeekNumber(
              prut.previousWeekNumber
            )} (${currentWeekRange})`}</b>
            ; collectors can review and edit data for until{" "}
            <b>{lastEditableDate}</b>.
          </li>
        </ul>
      }
      type="info"
      showIcon={true}
    />
  );
};

export default PreviousPrutBanner;
