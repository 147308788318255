import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Typography } from "antd";
import DefaultLayout from "../layout/DefaultLayout";
import Loader from "../components/Loader";
import PrutModal from "../components/PrutModal";
import PrutTable from "../components/PrutTable";
import CurrentPrutBanner from "../components/CurrentPrutBanner";
import { fetchMyPrut } from "../api/PrutAPI";
import { PrutByUser } from "../types/Prut";
import styles from "./ProjectDetails.module.css";

const MyPrut: React.FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const [prut, setPrut] = React.useState<PrutByUser>({
    currentWeekNumber: 0,
    currentWeekYear: 0,
    currentWeekStartDate: "",
    currentWeekEndDate: "",
    previousWeekNumber: 0,
    previousWeekYear: 0,
    projects: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState(false);
  const [reloadTable, setReloadTable] = React.useState<boolean>(false);

  const fetchPrutByUserApi = useCallback(() => {
    fetchMyPrut()
      .then((response) => {
        setPrut(response.data.prut);
        setLoading(false);
      })
      .finally(() => {
        setReloadTable(false);
      });
  }, [reloadTable]);

  useEffect(() => {
    fetchPrutByUserApi();
  }, [fetchPrutByUserApi]);

  return (
    <DefaultLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.page_container}>
          <CurrentPrutBanner prut={prut} />
          <Card>
            <PrutTable
              prut={prut}
              title={<Title level={5}>{t("Current PRUT Cycle")}</Title>}
              onOpenModal={() => setOpenModal(true)}
            />
          </Card>
          <PrutModal
            id=""
            showModal={openModal}
            onCloseModal={() => {
              setOpenModal(false);
            }}
            setReloadTable={setReloadTable}
            isMyPrut
          />
        </div>
      )}
    </DefaultLayout>
  );
};

export default MyPrut;
