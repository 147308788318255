import { get } from './API';

const syncContributors = async () => {
    const response = await get('/graph/sync', {});
    return response;
}

const fetchLastSyncHistory = async () => {
    const response = await get('/graph/sync-history', {});
    return response;
}

export { syncContributors, fetchLastSyncHistory };