export const formatTotalPercentage = (
  data: number,
  currArray: number[],
  currentValue: number,
) => {
  let sumPrut = 0;
  if (currArray.length <= 0) {
      currArray.push(0);
  }
  const index = currArray.indexOf(currentValue);
  currArray.splice(index, 1, data);
  currArray.forEach((x: number) => {
    sumPrut += x;
  });
  return {
    sumArray: currArray,
    projectContribution: sumPrut,
  };
};

export const formatPostData = <T extends { id: string | number }>(
  currentData: T[],
  previousData: T[]
) => {
  currentData.forEach((element) => {
    const itemIndex = previousData.findIndex((o) => o.id === element.id);
    if (itemIndex > -1) {
      previousData[itemIndex] = element;
    } else {
      previousData.push(element);
    }
  });

  return previousData;
};
