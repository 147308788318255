import React, { useEffect, useState } from "react";
import styles from "./AllProjects.module.css";
import { Button, Typography, notification } from "antd";
import { useTranslation } from "react-i18next";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import AllProjectsTable from "../components/AllProjectsTable";
import DefaultLayout from "../layout/DefaultLayout";
import Loader from "../components/Loader";
import { PermissionHelper } from "../helpers/PermissionHelper";
import { Permissions } from "../enum/Permissions";
import CreateProjectModal from "../components/CreateProjectModal";
import { Pic } from "../types/Pic";
import { fetchPics } from "../api/PicAPI";
import { fetchProjects } from "../api/ProjectAPI";
import { Project } from "../types/Project";
import { fetchPrutExport } from "../api/PrutAPI";
import UpdateProjectModal from "../components/UpdateProjectModal";
import AddContributorModal from "../components/AddContributorModal";
import {useAllProject} from "../hooks";

const AllProjects: React.FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const [pics, setPics] = useState<Array<Pic>>([]);
  const [shouldOpenDialog, setShouldOpenDialog] = useState(false);
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [permissions, setPermissions] = React.useState<Array<string>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const {
    editModalRef,
    addContributorRef,
    onOpenEditModal,
    onOpenAddContributor,
  } = useAllProject();

  const onOpenModal = () => {
    setShouldOpenDialog(true);
  };

  const onCloseModal = () => {
    setShouldOpenDialog(false);
  };

  const fetchProjectsData = () => {
    fetchProjects().then((response) => {
      setProjects(response.data.projects);
      setLoading(false);
    });
  };

  const fetchWeeklyUtilisation = () => {
    fetchPrutExport().then((response) => {
        const dataUrl = `data:'text/csv';base64,${response.data.result}`
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = a.download = 'Weekly Utilisation.csv';
        a.click();

        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Weekly utilisation downloaded!"),
          description: `The weekly utilisation has been successfully downloaded.`,
          className: styles.alert_success,
        });
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: styles.alert_error,
        });
      });
  }

  useEffect(() => {
    PermissionHelper.getPermissions().then((response) => {
      setPermissions(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchProjectsData();
  }, []);

  useEffect(() => {
    fetchPics().then((response) => {
      setPics(response.data.pics);
      setLoading(false);
    });
  }, []);

  return (
    <DefaultLayout>
      {loading ? <Loader /> :
        <div className={styles.page_container}>
          <div>
            <Title level={4}>{t('All Projects')}</Title>
          </div>

          <div className={styles.button_container}>
            {PermissionHelper.hasPermission(Permissions.CREATE_PROJECT, permissions) &&
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onOpenModal}
              >
                {t('Create Project')}
              </Button>
            }
            {PermissionHelper.hasPermission(Permissions.PRUT_EXPORT, permissions) &&
              <Button
                type="default"
                onClick={fetchWeeklyUtilisation}
                icon={<DownloadOutlined />}
              >
                {t('Export Weekly Utilisation')}
              </Button>
            }
          </div>

          <div>
            <AllProjectsTable
              permissions={permissions}
              pics={pics}
              projects={projects}
              loading={loading}
              onOpenEditModal={onOpenEditModal}
              onOpenAddContributor={onOpenAddContributor}
            />
          </div>

          <CreateProjectModal
            showModal={shouldOpenDialog}
            pics={pics}
            onRefetchData={fetchProjectsData}
            onClose={onCloseModal}
          />

          <UpdateProjectModal
            ref={editModalRef}
            pics={pics}
            onRefetchData={fetchProjectsData}
            permissions={permissions}
          />

          <AddContributorModal ref={addContributorRef} />
        </div>
      }
    </DefaultLayout>
  );
};

export default AllProjects;