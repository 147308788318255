import { PreviousPrutPostData, PrutPostData } from "../types/Prut";
import { get, post } from "./API";

const fetchPrutByProject = async (projectId: string) => {
  const response = await get('/prut/by-project', { id: projectId });
  return response;
};

const fetchPrutByUser = async (userId: string) => {
  const response = await get('/prut/by-user', { id: userId });
    return response;
};

const submitProjectContribution = async (projectContribution: PrutPostData[], ) => {
  const response = await post('/prut/update-by-users', projectContribution);
  return response;
};

const updateMyPrut = async (projectContribution: Omit<PrutPostData,"id">) => {
  const response = await post('/prut/update-my-prut', projectContribution);
  return response;
};

const fetchPrutExport = async () => {
  const response = await get('/prut/export', {});
  return response;
}

const updatePreviousByUsers = async (projectContribution: PreviousPrutPostData[]) => {
  const response = await post("/prut/update-previous-by-users", projectContribution);
  return response;
};

const fetchMyPrut = async () => {
  const response = await get('/prut/my-prut', {});
  return response;
};

export { fetchPrutByProject, fetchPrutByUser, submitProjectContribution, fetchPrutExport, fetchMyPrut, updateMyPrut, updatePreviousByUsers };
