import { deleteFn, get, post } from './API';

const checkContributorExist = async (params: { projectId: string, contributorId: string }) => {
    const response = await get("/contributor/get", params);
    return response;
}

const addContributor = async (body: { projectId: string, contributorIds: string[] }) => {
    const response = await post("/contributor/add", body);
    return response;
}

const deleteContributor = async (params: { projectId: string, contributorId: string }) => {
    const response = await deleteFn("/contributor/delete", params);
    return response;
}

export { checkContributorExist, addContributor, deleteContributor };
