import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import styles from './ErrorPageLayout.module.css';
import Header from './components/Header';

interface ErrorPageLayoutProps {
    children: ReactNode;
}

const ErrorPageLayout: React.FC<ErrorPageLayoutProps> = ({ children }) => {
    return (
        <Layout>
            <Header isErrorPage={true}/>
            <Layout className={styles.body_container}>
                {children}  
            </Layout>
        </Layout>
    );
}

export default ErrorPageLayout;