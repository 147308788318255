import { useRef, useState } from "react";
import { Project, UpdateProjectModalAction } from "../types/Project";

type Props = {
  project: Project;
};

const useProjectDetail = ({ project }: Props) => {
  const editModalRef = useRef<UpdateProjectModalAction>(null);
  const [reloadTable, setReloadTable] = useState<boolean>(false);

  const onOpenEditProject = () => {
    editModalRef.current?.open(project);
  };

  return { editModalRef, reloadTable, setReloadTable, onOpenEditProject };
};

export default useProjectDetail;
