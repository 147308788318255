import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb, { BreadcrumbItem } from "../components/BreadCrumb";
import { Card, Typography } from "antd";
import styles from "./ProjectDetails.module.css";
import Loader from "../components/Loader";
import CurrentPrutBanner from "../components/CurrentPrutBanner";
import { PrutByUser } from "../types/Prut";
import { fetchPrutByUser } from "../api/PrutAPI";
import PrutTable from "../components/PrutTable";
import PrutModal from "../components/PrutModal";
import { fetchContributor } from "../api/UserAPI";
import { Contributor } from "../types/Contributors";
import PreviousPrutBanner from "../components/PreviousPrutBanner";
import PreviousPrutModal from "../components/PreviousPrutModal";
import PreviousPrutTable from "../components/PreviousPrutTable";

const ContributorDetails: React.FC = () => {
  const { id = "" } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;

  const [contributor, setContributor] = React.useState<Contributor>({
    id: "",
    upn: "",
    active: false
  });
  const [prut, setPrut] = React.useState<PrutByUser>({
    currentWeekNumber: 0,
    currentWeekYear: 0,
    currentWeekStartDate: "",
    currentWeekEndDate: "",
    previousWeekNumber: 0,
    previousWeekYear: 0,
    projects: [],
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [reloadTable, setReloadTable] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPreviousModal, setOpenPreviousModal] = useState(false);

  const breadcrumbItems: BreadcrumbItem[] = [
    { path: "/contributors", name: t("All Contributors") },
    { path: "", name: contributor.upn + (!contributor.active ? ` (inactive)` : '') },
  ];

  const fetchPrutByUserApi = useCallback(() => {
    fetchPrutByUser(id).then((response) => {
        setPrut(response.data.prut);
        setLoading(false);
      }).finally(() => {
        setReloadTable(false);
      });
  }, [id]);

  useEffect(() => {
    fetchContributor(id).then((response) => {
      setContributor(response.data.contributor);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    fetchPrutByUserApi();
  }, [fetchPrutByUserApi]);

  useEffect(() => {
    reloadTable && fetchPrutByUserApi();
  }, [reloadTable, fetchPrutByUserApi]);

  return (
    <DefaultLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.page_container}>
          <div className={styles.page_header}>
            <Breadcrumb items={breadcrumbItems} />
            <div className={styles.project_contributors_header}>
              <Title level={4} className={styles.page_header_title}>
                {contributor.upn}
              </Title>
              {!contributor.active && (
                <Title level={4} className={styles.page_header_title}>
                  ({t("inactive")})
                </Title>
              )}
            </div>
          </div>
          <Card title={<Title level={5}>{t("Current PRUT Cycle")}</Title>}>
            <div className={styles.project_contributors_container}>
              <CurrentPrutBanner prut={prut} />
              <PrutTable prut={prut} onOpenModal={() => setOpenModal(true)} />
            </div>
          </Card>
          <Card title={<Title level={5}>{t("Previous PRUT Cycle")}</Title>}>
            <div className={styles.project_contributors_container}>
              <PreviousPrutBanner prut={prut} />
              <PreviousPrutTable
                prut={prut}
                onOpenModal={() => setOpenPreviousModal(true)}
              />
            </div>
          </Card>
          <PrutModal
            id={id}
            upn={contributor.upn}
            showModal={openModal}
            onCloseModal={() => {
              setOpenModal(false);
            }}
            setReloadTable={setReloadTable}
          />
          <PreviousPrutModal
            id={id}
            upn={contributor.upn}
            showModal={openPreviousModal}
            onCloseModal={() => {
              setOpenPreviousModal(false);
            }}
            setReloadTable={setReloadTable}
          />
        </div>
      )}
    </DefaultLayout>
  );
};

export default ContributorDetails;