import React from "react";
import { Divider, Typography, Button } from "antd";
import { WindowsFilled } from "@ant-design/icons";
import LoginLayout from "../layout/LoginLayout";
// import { useMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../authConfig";
import { useParams } from "react-router-dom";
import { StorageHelper } from "../helpers/StorageHelper";
import { PublicClientApplication } from "@azure/msal-browser";
import { getTenant } from "../api/TenantAPI";

const Login: React.FC = () => {
  const { Title } = Typography;
  // const { instance } = useMsal();
  const { tenantId } = useParams();

  const handleLoginRedirect = async () => {
    if (tenantId === undefined)
      return;

    let response;
    try {
      response = await getTenant(tenantId);
    } catch {
      return;
    }

    StorageHelper.setTenantConfig({
      id: response.data.tenant.id,
      tenantId: response.data.tenant.tenantId,
      name: response.data.tenant.name,
      tenantAdId: response.data.tenant.tenantAdId,
      clientId: response.data.tenant.clientId,
      clientSecret: response.data.tenant.clientSecret,
      apiClientId: response.data.tenant.apiClientId,
      groupIds: response.data.tenant.groupIds,
      status: response.data.tenant.status
    });

    const instance = new PublicClientApplication(msalConfig());
    await instance.initialize();
    instance.loginRedirect(loginRequest()).catch((error) => console.error(error));
  };

  return (
    <LoginLayout>
      <div>
        <Title level={5}>
          Welcome to our first stop for SANDP1T Collective members!
        </Title>
      </div>
      <Divider />
      <Button type="primary" icon={<WindowsFilled />} size="large" onClick={() => handleLoginRedirect()}>
        Sign in with Microsoft
      </Button>
    </LoginLayout>
  );
};

export default Login;
