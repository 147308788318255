import React, { KeyboardEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ModalProps as AntDModalProps, AutoComplete, ButtonProps, Divider, Form, Input, SelectProps, Spin, Tag, Typography, notification } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { LabeledValue } from 'antd/lib/select';
import { addContributor, checkContributorExist } from '../api/ContributorAPI';
import { AddContributorModalAction, Contributor } from '../types/Contributors';
import { fetchAllContributors } from '../api/UserAPI';
import Modal from "./Modal";
import styles from './AddContributorModal.module.css';
import { useAddContributorModal } from '../hooks';

interface AddContributorModalProps extends AntDModalProps {
  onRefetchData?: (reloadTable: boolean) => void;
}

type AutoCompleteOptionType = Contributor & LabeledValue;

type AutoCompleteProps = SelectProps<string, AutoCompleteOptionType>;

const MAXIMUM_CONTRIBUTORS = 5;

const AddContributorModal = forwardRef<AddContributorModalAction, AddContributorModalProps>(function AddContributorModalInner({ onRefetchData }, ref) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({ projectId: '', projectName: "" });
  const [contributors, setContributors] = useState<AutoCompleteOptionType[]>([]);
  const [contributorsOptions, setContributorsOptions] = useState<AutoCompleteProps['options']>([]);
  const [formSubmit, setFormSubmit] = useState<AutoCompleteOptionType[]>([]);
  const { inputSearchRef, submitRef, openDropdown, setOpenDropdown, onModalOk, onSearchBarFocus } = useAddContributorModal();
  const { projectId, projectName } = modalInfo;

  const searchResult = (query: string) => {
    const result = contributors
      .filter((item) => item.upn.toLowerCase().includes(query.toLowerCase()))
    return result;
  };

  const handleSearch = (value: string) => {
    const isError = form.getFieldsError().some((item) => item.errors.length > 0);
    if (isError) {
      form.setFields([
        {
          name: "upn",
          errors: []
        },
      ]);
      setButtonDisabled(false)
    }
    if (value) {
      setOpenDropdown(true);
      setContributorsOptions(searchResult(value));
    } else {
      setOpenDropdown(false);
      setContributorsOptions([])
    }
  };

  const getContributor = async () => {
    await fetchAllContributors().then(({ data }) => {
      setContributors(data.map((item: AutoCompleteOptionType) => {
        const upn = item.upn + (!item.active ? ' (inactive)' : '');
        return { ...item, label: upn, value: upn, upn }
      }));
    })
  }

  const onSelect: AutoCompleteProps['onSelect'] = (value, option) => {
    setOpenDropdown(false);
    checkContributorExist({ projectId, contributorId: option.id }).then(({ data }) => {
      if (data.result) {
        form.setFields([
          {
            name: 'upn',
            errors: [`${value} is an existing contributor in this project.`],
          },
        ]);
        setButtonDisabled(true);
      } else {
        setFormSubmit((pre: AutoCompleteOptionType[]) => {
          const cloneData = [...pre];
          cloneData.push(option);
          return cloneData
        });
        setContributors((pre: AutoCompleteOptionType[]) => {
          const cloneData = [...pre];
          return cloneData.filter(item => item.id !== option.id);
        });
        form.resetFields();
        setButtonDisabled(false);
      }
    }).catch(() => {
      notification.open({
        type: "error",
        placement: "topRight",
        top: 84,
        message: t("Action Failed"),
        description: t(
          "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
        ),
        className: "notification-error",
      });
    })
  }

  const onRemoveContributor = (id: string, item: AutoCompleteOptionType) => {
    setFormSubmit((pre: AutoCompleteOptionType[]) => {
      const cloneData = [...pre];
      return cloneData.filter(item => item.id !== id)
    })
    setContributors((pre: AutoCompleteOptionType[]) => {
      const cloneData = [...pre];
      cloneData.push(item);
      return cloneData;
    });
  }

  const handleSearchOnBlur = () => {
    setOpenDropdown(false);
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onSubmit = () => {
    const contributorIds = formSubmit.map(item => item.id);
    setFormLoading(true);
    addContributor({ contributorIds, projectId }).then(() => {
      notification.open({
        type: "success",
        placement: "topRight",
        top: 84,
        message: t("New project contributor(s) added!"),
        description: `Contributor(s) have been successfully added to ${projectName}.`,
        className: "notification-success",
      });
      onRefetchData?.(true);
      close();
    }).catch(() => {
      notification.open({
        type: "error",
        placement: "topRight",
        top: 84,
        message: t("Action Failed"),
        description: t(
          "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
        ),
        className: "notification-error",
      });
    }).finally(() => {
      setFormLoading(false);
    })
  }

  const open = useCallback((info: { projectId: string, projectName: string }) => {
    setModalInfo(info)
    setOpenModal(true);
  }, []);

  const close = useCallback(() => {
    setOpenModal(false);
    form.resetFields();
    setButtonDisabled(false);
    setFormSubmit([])
  }, [form]);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  useEffect(() => {
    if (openModal) {
      getContributor();
      setTimeout(() => {
        inputSearchRef.current?.focus();
      }, 0);
    }
  }, [inputSearchRef, openModal])

  return (
    <Modal
      title={
        <Title level={5} className={styles.modal_title}>
          {t("Add Contributor")}
        </Title>
      }
      data-testid="add_contributor_modal"
      open={openModal}
      onOk={onModalOk}
      onCancel={close}
      okText={t("Add Contributor")}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: buttonDisabled || formSubmit.length == 0,
        } as ButtonProps
      }
      size="medium"
      destroyOnClose
    >
      <div className={styles.modal_content_container}>
        {formLoading ? (
          <div className={styles.modal_loading_wrapper}>
            <Spin
              indicator={
                <Loading3QuartersOutlined
                  spin
                  className={styles.modal_loading}
                />
              }
              size="large"
            />
          </div>
        ) : (
          <>
            <div>You can add up to 5 contributors at a time to <b>{modalInfo.projectName}</b>.</div>
            <Divider />
            <Form form={form} onFinish={onSubmit}>
              <Form.Item name="upn">
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  options={contributorsOptions}
                  onSearch={handleSearch}
                  onSelect={onSelect}
                  notFoundContent={t("No results found")}
                  autoFocus
                  open={openDropdown}
                  onBlur={handleSearchOnBlur}
                  onFocus={onSearchBarFocus}
                  disabled={formSubmit.length >= MAXIMUM_CONTRIBUTORS}
                >
                  <Input.Search
                    ref={inputSearchRef}
                    width="100%"
                    size="middle"
                    data-testid="current_value_input"
                    placeholder="Enter Contributor’s Email"
                    onKeyDown={handleKeyDown}
                  />
                </AutoComplete>
              </Form.Item>
              <input
                data-testid="submit-form"
                ref={submitRef}
                type="submit"
                hidden
              />
            </Form>
            {formSubmit && formSubmit.length > 0 && (
              <div className={styles.tag_wrapper}>
                {formSubmit.map((item) => (
                  <Tag
                    data-testid="contributor-tag"
                    key={item.id}
                    closable
                    onClose={() => onRemoveContributor(item.id, item)}
                  >
                    {item.upn}
                  </Tag>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
})

export default AddContributorModal