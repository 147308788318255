import React from 'react';
import './App.css';
import RoutesComponent from './routes/Routes';


document.title = 'P1TSTOP';
const App: React.FC = () => {
  return (
    <RoutesComponent />
  );
};

export default App;
