import { Button, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./AllContributorsTable.module.css";
import { fetchAllContributors } from "../api/UserAPI";
import { UserOutlined } from "@ant-design/icons";
import { ContributorPrut } from "../types/Prut";
import { DataTableHelper } from "../helpers/DataTableHelper";
import { DateHelper } from "../helpers/DateHelper";
import PrutModal from "./PrutModal";
import PreviousPrutModal from "./PreviousPrutModal";
import { Weekday } from "../enum/Weekday";

const AllContributorsTable: React.FC = () => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const navigate = useNavigate();
  const [ contributors, setContributors ] = useState<Array<ContributorPrut>>([]);
  const [ loading, setLoading ] = useState<boolean>(true);
  const [, setReloadTable] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPreviousModal, setShowPreviousModal] = useState<boolean>(false);
  const [userId, setUserId] = useState('');
  const [userUpn, setUserUpn] = useState('');
  const editPreviousPrutStart = isNaN(
    Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_START)
  )
    ? -1
    : Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_START);
  const editPreviousPrutEnd = isNaN(
    Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_END)
  )
    ? -1
    : Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_END);
  const isCurrentWeekdayInRange = DateHelper.isCurrentWeekdayInRange(
    editPreviousPrutStart >= Weekday.SUN && editPreviousPrutStart <= Weekday.SAT
      ? editPreviousPrutStart
      : Weekday.MON,
    editPreviousPrutEnd >= Weekday.SUN && editPreviousPrutEnd <= Weekday.SAT
      ? editPreviousPrutEnd
      : Weekday.WED
  );

  useEffect(() => {
    fetchAllContributors().then((response) => {
      setContributors(response.data);
      setLoading(false);
    });
  }, []);

  const columns: ColumnsType<ContributorPrut> = [
    {
      title: t('Contributor\'s Name'),
      dataIndex: 'upn',
      width: '31.25rem',
      sorter: (a, b) => DataTableHelper.stringSorter(a.upn, b.upn),
      defaultSortOrder: 'ascend',
      render: (text: string, contributor: ContributorPrut) => {
        return (
          <Text
            className={styles.contributor_upn}
            onClick={() => navigate(`/contributors/${contributor.id}`)}
          >
            <UserOutlined /> {text}{!contributor.active && ' (inactive)'}
          </Text>
        )
      }
    },
    {
      title: t('Action(s)'),
      width: '8.75rem',
      render: (_, record) => {
        return (
          <div className={styles.actions}>
            <Button
              data-testid="button-edit-current-test"
              className={styles.no_padding}
              type="link"
              onClick={() => {
                setShowModal(true);
                setUserId(record.id);
                setUserUpn(record.upn + (!record.active ? " (inactive)" : ""));
              }}
            >
              {t("Edit Current PRUT")}
            </Button>
            <Button
              data-testid="button-edit-previous-test"
              className={styles.no_padding}
              type="link"
              disabled={!isCurrentWeekdayInRange}
              onClick={() => {
                setShowPreviousModal(true);
                setUserId(record.id);
                setUserUpn(record.upn + (!record.active ? " (inactive)" : ""));
              }}
            >
              {t("Edit Previous PRUT")}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table loading={loading} columns={columns} dataSource={contributors} pagination={false} rowKey="id"/>
      <PrutModal
        id={userId}
        showModal={showModal}
        upn={userUpn}
        onCloseModal={() => {
          setUserId(""); // turn off the userid first to trigger another fetch in the modal
          setUserUpn("");
          setShowModal(false);
        }}
        setReloadTable={setReloadTable}
      />
      <PreviousPrutModal
        id={userId}
        showModal={showPreviousModal}
        upn={userUpn}
        onCloseModal={() => {
          setUserId(""); // turn off the userid first to trigger another fetch in the modal
          setUserUpn("");
          setShowPreviousModal(false);
        }}
        setReloadTable={setReloadTable}
      />
    </>
  );
}

export default AllContributorsTable;