import { get } from '../api/API';

class ProtectedAssetsHelper {
    async getUrl(key: string): Promise<string> {
        // Expecting object of the form:
        // {
        //     "url": <some url>,
        //     "status": "success"
        // }

        try {
            const response = await get(`/protectedAssets/get?key=${key}`, {});
            if (response && response.data && response.data.url) {
                return response.data.url;
            } else {
                console.error("Data not found in response object. Key: " + key);
                throw new Error('Failed to fetch URL from backend');
            }
        } catch (error) {
            // Handle error
            console.error("Error fetching URL from backend. Key: " + key);
            throw new Error('Failed to fetch URL from backend');
        }
    }
}

export default ProtectedAssetsHelper;