import React from "react";
import styles from './Header.module.css';
import { Header as AntDHeader, BasicProps } from "antd/lib/layout/layout";
import { Button, Dropdown, Image, MenuProps, Typography } from "antd";
import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/p1tstop-logo.svg';
import { ConfigHelper } from "../../helpers/ConfigHelper";
import { useMsal } from "@azure/msal-react";
import Modal from "../../components/Modal";

interface HeaderProps extends BasicProps {
    isErrorPage?: boolean;
}

const Header: React.FC<HeaderProps> = ({ isErrorPage=false, ...props }) => {
    const navigate = useNavigate();
    const { instance } = useMsal();

    const { Text, Title } = Typography;

    const [showModal, setShowModal] = React.useState(false);
    
    const handleLogout = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
        });
    }

    const mailTo = () => {
        const feedbackEmail = ConfigHelper.getConfig('REACT_APP_FEEDBACK_MAIL', '');
        const mailSubject = "Feedback for P1TSTOP";
        const mailBody = "Please share your feedback with us. Your insights will help us enhance your experience.";
        window.location.href = `mailto:${feedbackEmail}?subject=${mailSubject}&body=${mailBody}`;
    };

    const items: MenuProps['items'] = [
        { label: 'Submit Feedback', key: 'submit-feedback', onClick: () => mailTo() },
        { label: 'Logout', key: 'logout', onClick: () => setShowModal(true) },
    ];

    return (
        <>
            <AntDHeader className={styles.header} {...props}>
                <div className={styles.header_body}>
                    <Image
                        preview={false}
                        src={Logo}
                        alt="logo"
                        className={styles.header_logo}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                    {
                        !isErrorPage && 
                            <div>
                                <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
                                    <Button type="ghost" icon={<UserOutlined />}>{instance.getActiveAccount()?.username ?? ""}</Button>
                                </Dropdown>
                            </div>
                    }
                </div>
            </AntDHeader>
            <Modal
                title={
                    <div className={styles.logout_modal_title}>
                        <ExclamationCircleOutlined className={styles.logout_modal_title_icon}/>
                        <Title level={5}>Logout?</Title>
                    </div>
                }
                open={showModal}
                onOk={() => {
                    setShowModal(false);
                    handleLogout();
                }}
                onCancel={() => {
                    setShowModal(false);
                }}
                okText="Confirm"
            >
                <Text>Are you sure you want to log out? Any unsaved changes will be lost.</Text>
            </Modal>
        </>
        
    );
}

export default Header;