import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { AxiosInstance } from "axios";
import { loginRequest, msalConfig } from "../authConfig";

const initInterceptors = (axiosInstance: AxiosInstance): PublicClientApplication => {
    const pca = new PublicClientApplication(msalConfig());
    const request = loginRequest();
    axiosInstance.interceptors.request.use(
        async function (config) {
            await pca.initialize();
            const accessToken = await pca.acquireTokenSilent(request).then((res) => {
                return res.accessToken
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    pca.loginRedirect(request);
                }
            });
            config.headers.set("Authorization", `Bearer ${accessToken}`);
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => response,
        function (error) {
            if (error.response.status === 401) {
                window.location.href = '/error/unauthorised';
            } else if (error.response.status === 404) {
                window.location.href = '/error';
            }
            return Promise.reject(error);
        }
    );

    return pca;
}

export { initInterceptors };