import { Alert } from "antd";
import React from "react";
import styles from "./CurrentPrutBanner.module.css";
import { DateHelper } from "../helpers/DateHelper";

interface CurrentPrutBannerProps {
  prut: {
    currentWeekNumber: number;
    currentWeekYear: number;
    currentWeekStartDate: string;
    currentWeekEndDate: string;
    previousWeekNumber: number;
    previousWeekYear: number;
  };
}

const CurrentPrutBanner: React.FC<CurrentPrutBannerProps> = ({ prut }) => {
  const mondayOfWeek = new Date(prut.currentWeekStartDate);
  const currentWeekRange = DateHelper.getCurrentWeekRange(mondayOfWeek);
  const lastEditableDate = DateHelper.getNextSunday(mondayOfWeek);

  return (
    <Alert
      message={
        <ul className={styles.list}>
          <li>
            <b>Current PRUT</b> shown is for{" "}
            <b>{`Week ${DateHelper.formatWeekNumber(
              prut.currentWeekNumber
            )} (${currentWeekRange})`}</b>
            ; data for ongoing project(s) is editable until <b>{lastEditableDate}</b>.
          </li>
          <li>
            <b>Previous PRUT</b> shown is for{" "}
            <b>{`Week ${DateHelper.formatWeekNumber(
              prut.previousWeekNumber
            )}`}</b>
            .
          </li>
        </ul>
      }
      type="info"
      showIcon={true}
    />
  );
};

export default CurrentPrutBanner;
