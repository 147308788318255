import React, { useEffect, useState } from "react";
import styles from "./QuarterlyBonus.module.css";
import { Button, Card, Collapse, Divider, Tooltip, Typography } from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import DefaultLayout from "../layout/DefaultLayout";
import Loader from "../components/Loader";
import { ConfigHelper } from "../helpers/ConfigHelper";
import { fetchBonusDetails, fetchBonusSummary } from "../api/BonusAPI";
import { BonusDetails, BonusSummary } from "../types/QuarterlyBonus";
import { DateHelper } from "../helpers/DateHelper";
import { PermissionHelper } from "../helpers/PermissionHelper";
import { Permissions } from "../enum/Permissions";
import BonusModal from "../components/BonusModal";
import { useNavigate } from "react-router-dom";
import { bonusStaticInfo } from "../helpers/BonusStaticInfoHelper";


type BonusItemProps = {
  title: string,
  value: string,
  tooltip?: string | React.ReactNode | undefined
}

const BonusSummaryItem: React.FC<BonusItemProps> = ({ title, value, tooltip }) => {
  const { Title, Text } = Typography;
  return (
    <div>
      <div className={styles.summary_title}>
        <Text type="secondary">
          {title}
        </Text>
        {tooltip && (
          <Tooltip
            placement="right"
            title={tooltip}
          >
            <InfoCircleOutlined className={styles.tooltip_icon} />
          </Tooltip>
        )}
      </div>
      <Title className={styles.title4} level={4}>
        {value}
      </Title>
    </div>
  );
};

const processBonusDetail = (rawString: string) => {
  const simpleProcessing = (rawString3: string) => rawString3.replace("&gt;", ">").replace("&lt;", "<");
  const processForU = (rawString4: string) => {
    if (rawString4.includes("<u>")) {
      return rawString4.split(/<\/?u>/g).map((line, index) => {
        // We only have to underline those lines which are enclosed in <u> tags. They are at odd indexes since it's split by <u> and </u> tags.
        if (index % 2 === 1) {
          return <React.Fragment key={index}><u>{simpleProcessing(line)}</u></React.Fragment>;
        }
        return simpleProcessing(line);
      });
    }
    return simpleProcessing(rawString4);
  };
  const processForBr = (rawString1: string) =>
    rawString1.split(/<br ?\/?>/g).filter((item) => item != null && item !== "").map((line, index) => (
      <React.Fragment key={index}>
        {processForU(line)}
        <br />
      </React.Fragment>
    ));
  const processForLi = (rawString2: string) => {
    if (rawString.includes("<li>")) {
      return (
        <React.Fragment>
          <ul>
            {rawString2.split(/<\/?li>/g)
              .filter((item) => item != null && item !== "")
              .map((line, index) => (
                <React.Fragment key={index}>
                  {line.trim() && <li>{processForBr(line.trim())}</li>}
                </React.Fragment>
              ))}
          </ul>
        </React.Fragment>
      );
    }
    return processForBr(rawString2);
  };
  const processForUl = (rawString: string) => {
    if (rawString.includes("<ul>")) {
      return rawString.split(/<\/?ul>/g)
        .filter((item) => item != null && item !== "")
        .map((item, index) => <React.Fragment key={`ul-${index}`}>{processForLi(item)}</React.Fragment>);
    }
    return processForBr(rawString);
  };

  return processForUl(rawString);
};

const BonusDetailsItem: React.FC<BonusItemProps> = ({ title, value, tooltip }) => {
  const { Text } = Typography;
  return (
    <div className={styles.bonus_details_item_container}>
      <div className={styles.bonus_details_item_title_container}>
        <div className={styles.bonus_details_item_title}>
          <Text>
            {title}
          </Text>
          {tooltip && (
            <Tooltip
              placement="right"
              title={tooltip}
            >
              <InfoCircleOutlined className={styles.tooltip_icon} />
            </Tooltip>
          )}
        </div>
        <Text>:</Text>
      </div>
      <div>
        <Text className={styles.bonus_details_item_text}>{value}</Text>
      </div>
    </div>
  );
}

const QuarterlyBonus: React.FC = () => {
  const navigate = useNavigate();
  const { Title, Text, Link } = Typography;
  const { Panel } = Collapse;

  const [loading, setLoading] = useState<boolean>(true);
  const [permissions, setPermissions] = React.useState<Array<string>>([]);
  const [openBonusModal, setOpenBonusModal] = useState<boolean>(false);

  const [bonusSummary, setBonusSummary] = useState<BonusSummary>({
    bonusPeriod: "",
    lastModified: "",
    payoutMonth: "",
    bonusPerPerson: "",
  });

  const [bonusDetails, setBonusDetails] = useState<BonusDetails>({
    totalRevenue: "",
    totalCollection: "",
    collectionRatio: "",
    overallProjectBudget: "",
    overallCostOfRevenue: "",
    totalForecastedQuarterlyBonus: "",
    totalPayableQuarterlyBonus: "",
    totalEligiblePersons: "",
    quarterlyBonusPerPerson: "",
    remarks: "",
  });

  const handleBonusSubmitted = (newBonus: any) => {
    setBonusSummary({
      bonusPeriod: newBonus.data.bonusLatest.bonusPeriod,
      lastModified: newBonus.data.bonusLatest.lastModified,
      payoutMonth: newBonus.data.bonusLatest.payoutMonth,
      bonusPerPerson: newBonus.data.bonusLatest.quarterlyBonusPerPerson,
    });

    setBonusDetails({
      totalRevenue: newBonus.data.bonusLatest.totalRevenue,
      totalCollection: newBonus.data.bonusLatest.totalCollection,
      collectionRatio: newBonus.data.bonusLatest.collectionRatio,
      overallProjectBudget: newBonus.data.bonusLatest.overallProjectBudget,
      overallCostOfRevenue: newBonus.data.bonusLatest.overallCostOfRevenue,
      totalForecastedQuarterlyBonus: newBonus.data.bonusLatest.totalForecastedQuarterlyBonus,
      totalPayableQuarterlyBonus: newBonus.data.bonusLatest.totalPayableQuarterlyBonus,
      totalEligiblePersons: newBonus.data.bonusLatest.totalEligiblePersons,
      quarterlyBonusPerPerson: newBonus.data.bonusLatest.quarterlyBonusPerPerson,
      remarks: newBonus.data.bonusLatest.remarks,
    });
  };


  const obpURL = ConfigHelper.getConfig("REACT_APP_OBP_URL", "");

  useEffect(() => {
    fetchBonusSummary()
      .then((data) => {
        setBonusSummary(data.data.bonusSummary);
        setLoading(false);
      })
      .catch((error) => {
        navigate('/error/oops')
      })
  }, [navigate]);

  useEffect(() => {
    fetchBonusDetails()
      .then((data) => {
        setBonusDetails(data.data.bonusDetails);
        setLoading(false);
      })
      .catch((error) => {
        navigate('/error/oops')
      })
  }, [navigate]);

  useEffect(() => {
    PermissionHelper.getPermissions().then((response) => {
      setPermissions(response);
    })
      .catch((error) => {
        navigate('/error/oops')
      })
  }, [navigate]);

  const handleOpenBonusModal = () => {
    setOpenBonusModal(true);
  }

  return (
    <DefaultLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.page_container}>
          <div className={styles.title_container}>
            <div>
              <Title className={styles.page_title} level={4}>Quarterly Bonus</Title>
              <Text data-testid="bonus-text" type="secondary">Last updated: {bonusSummary.lastModified ? DateHelper.formatDateWithTime(new Date(bonusSummary.lastModified)) : '-'}</Text>
            </div>
            {PermissionHelper.hasPermission(Permissions.CREATE_BONUS, permissions) &&
              <div>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  data-testid="updateBtn"
                  onClick={handleOpenBonusModal}
                >
                  Update Quarterly Bonus
                </Button>
              </div>
            }
          </div>
          <Card>
            <div className={styles.card_container}>
              <div>
                <div>
                  <BonusSummaryItem
                    title="Quarterly Bonus Period"
                    value={bonusSummary.bonusPeriod ? bonusSummary.bonusPeriod : "-"}
                    tooltip="The specific timeframe during which a quarterly bonus is calculated"
                  />
                </div>
                <div className={styles.bonus_container}>
                  <BonusSummaryItem
                    title="Quarterly Bonus Per Person"
                    value={bonusSummary.bonusPerPerson ? `$ ${bonusSummary.bonusPerPerson}` : "-"}
                    tooltip={
                      <div className={styles.tooltip_text}>
                        <Text>
                          {bonusStaticInfo.quarterlyBonusPerPerson.tooltip.text}
                        </Text>
                        <Text>Formula: <br />
                          {processBonusDetail(bonusStaticInfo.quarterlyBonusPerPerson.tooltip.formula)}
                        </Text>
                      </div>
                    }
                  />
                  <BonusSummaryItem
                    title="Payout Month"
                    value={bonusSummary.payoutMonth ? bonusSummary.payoutMonth : "-"}
                  />
                </div>
              </div>
              <Collapse>
                <Panel header="View Full Details" key="1">
                  <div className={styles.bonus_details_container}>
                    <BonusDetailsItem
                      title="Total Revenue"
                      value={bonusDetails.totalRevenue ? `$ ${bonusDetails.totalRevenue}` : "-"}
                      tooltip={processBonusDetail(bonusStaticInfo.totalRevenue.tooltip.text)}
                    />
                    <BonusDetailsItem
                      title="Total Collection"
                      value={bonusDetails.totalCollection ? `$ ${bonusDetails.totalCollection}` : "-"}
                      tooltip={processBonusDetail(bonusStaticInfo.totalCollection.tooltip.text)}
                    />
                    <BonusDetailsItem
                      title="Collection Ratio"
                      value={bonusDetails.collectionRatio ? `${bonusDetails.collectionRatio}` : "-"}
                      tooltip={
                        <div className={styles.tooltip_text}>
                          <Text>
                            {processBonusDetail(bonusStaticInfo.collectionRatio.tooltip.text)}
                          </Text>
                          <Text>Formula: <br />

                            {processBonusDetail(bonusStaticInfo.collectionRatio.tooltip.formula)}
                          </Text>
                        </div>
                      }
                    />
                    <Divider className={styles.no_margin} />
                    <BonusDetailsItem
                      title="Overall Project Budget"
                      value={bonusDetails.overallProjectBudget ? `$ ${bonusDetails.overallProjectBudget}` : "-"}
                      tooltip={
                        <div className={styles.tooltip_text}>
                          <Text>
                            {processBonusDetail(bonusStaticInfo.overallProjectBudget.tooltip.text)}
                          </Text>
                          <Text>Formula: <br />
                            {processBonusDetail(bonusStaticInfo.overallProjectBudget.tooltip.formula)}
                          </Text>
                        </div>
                      }
                    />
                    <BonusDetailsItem
                      title="Overall Cost of Revenue"
                      value={bonusDetails.overallCostOfRevenue ? `$ ${bonusDetails.overallCostOfRevenue}` : "-"}
                      tooltip={processBonusDetail(bonusStaticInfo.overallCostOfRevenue.tooltip.text)}
                    />
                    <BonusDetailsItem
                      title="Total Forecasted Quarterly Bonus"
                      value={bonusDetails.totalForecastedQuarterlyBonus ? `$ ${bonusDetails.totalForecastedQuarterlyBonus}` : "-"}
                      tooltip={
                        <div className={styles.tooltip_text}>
                          <Text>
                            {processBonusDetail(bonusStaticInfo.totalForecastedQuarterlyBonus.tooltip.text)}
                          </Text>
                          <Text>Formula: <br />
                            {processBonusDetail(bonusStaticInfo.totalForecastedQuarterlyBonus.tooltip.formula)}
                          </Text>
                        </div>
                      }
                    />
                    <Divider className={styles.no_margin} />
                    <BonusDetailsItem
                      title="Total Payable Quarterly Bonus"
                      value={bonusDetails.totalPayableQuarterlyBonus ? `$ ${bonusDetails.totalPayableQuarterlyBonus}` : "-"}
                      tooltip={
                        <div className={styles.tooltip_text}>
                          <Text>
                            {processBonusDetail(bonusStaticInfo.totalPayableQuarterlyBonus.tooltip.text)}
                          </Text>
                          <Text>Formula: <br />
                            {processBonusDetail(bonusStaticInfo.totalPayableQuarterlyBonus.tooltip.formula)}
                          </Text>
                        </div>
                      }
                    />
                    <BonusDetailsItem
                      title="No. of Eligible Persons"
                      value={bonusDetails.totalEligiblePersons ? `${bonusDetails.totalEligiblePersons}` : "-"}
                      tooltip={processBonusDetail(bonusStaticInfo.totalEligiblePersons.tooltip.text)}
                    />
                    <BonusDetailsItem
                      title="Quarterly Bonus Per Person"
                      value={bonusDetails.quarterlyBonusPerPerson ? `$ ${bonusDetails.quarterlyBonusPerPerson}` : "-"}
                      tooltip={
                        <div className={styles.tooltip_text}>
                          <Text>
                            {processBonusDetail(bonusStaticInfo.quarterlyBonusPerPerson.tooltip.text)}
                          </Text>
                          <Text>Formula: <br />
                            {processBonusDetail(bonusStaticInfo.quarterlyBonusPerPerson.tooltip.formula)}
                          </Text>
                        </div>
                      }
                    />
                    <BonusDetailsItem
                      title="Remarks"
                      value={bonusDetails.remarks ? bonusDetails.remarks : "-"}
                    />
                    <Divider className={styles.no_margin} />
                    <Text>
                      Refer to{" "}
                      <Link href={obpURL} target="_blank">
                        Operator/Overwatch Bonus Policy on Confluence{" "}
                      </Link>{" "}
                      for more details.
                    </Text>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </Card>
        </div>
      )}
      {PermissionHelper.hasPermission(Permissions.CREATE_BONUS, permissions) &&
        <BonusModal
          showModal={openBonusModal}
          setShowModal={setOpenBonusModal}
          bonus={{
            bonusPeriod: bonusSummary.bonusPeriod,
            payoutMonth: bonusSummary.payoutMonth,
            ...bonusDetails,
          }}
          onBonusSubmitted={handleBonusSubmitted}
        />
      }
    </DefaultLayout>
  );
};

export default QuarterlyBonus;
