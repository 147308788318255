import { useRef } from "react";
import { Project, UpdateProjectModalAction } from "../types/Project";
import { AddContributorModalAction } from "../types/Contributors";

const useAllProject = () => {
  const editModalRef = useRef<UpdateProjectModalAction>(null);
  const addContributorRef = useRef<AddContributorModalAction>(null);

  const onOpenEditModal = (info: Project) => {
    editModalRef.current?.open(info);
  };

  const onOpenAddContributor = (info: Project) => {
    addContributorRef.current?.open({
      projectId: info.id,
      projectName: info.name,
    });
  };

  return {
    editModalRef,
    addContributorRef,
    onOpenEditModal,
    onOpenAddContributor,
  };
};

export default useAllProject;
