import React, { useState } from "react";
import { Button, Table, TableColumnsType, Tag, Typography } from "antd";
import { DataTableHelper } from "../helpers/DataTableHelper";
import { ProjectHelper } from "../helpers/ProjectHelper";
import { ProjectContribution, PrutByUser } from "../types/Prut";
import { useTranslation } from "react-i18next";
import styles from "./PrutModal.module.css";
import { fetchPics } from "../api/PicAPI";
import { EditOutlined } from "@ant-design/icons";
import { DateHelper } from "../helpers/DateHelper";
import { Weekday } from "../enum/Weekday";

interface PreviousPrutTableProps {
  prut: PrutByUser;
  onOpenModal: VoidFunction;
}

const PreviousPrutTable = ({ prut, onOpenModal }: PreviousPrutTableProps) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [pics, setPics] = useState([]);
  const editPreviousPrutStart = isNaN(
    Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_START)
  )
    ? -1
    : Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_START);
  const editPreviousPrutEnd = isNaN(
    Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_END)
  )
    ? -1
    : Number(process.env.REACT_APP_EDIT_PREVIOUS_PRUT_WEEKDAY_END);
  const isCurrentWeekdayInRange = DateHelper.isCurrentWeekdayInRange(
    editPreviousPrutStart >= Weekday.SUN && editPreviousPrutStart <= Weekday.SAT
      ? editPreviousPrutStart
      : Weekday.MON,
    editPreviousPrutEnd >= Weekday.SUN && editPreviousPrutEnd <= Weekday.SAT
      ? editPreviousPrutEnd
      : Weekday.WED
  );
  React.useEffect(() => {
    fetchPics().then((response) => {
      setPics(response.data.pics);
    });
  }, []);

  const projectContributionColumns: TableColumnsType<ProjectContribution> = [
    {
      title: "Project Title",
      dataIndex: ["project", "name"],
      width: "19.75rem",
      ellipsis: true,
      sorter: (a, b) =>
        DataTableHelper.stringSorter(a.project.name, b.project.name),
      render: (projectTitle: string) => <Text>{projectTitle}</Text>,
    },
    {
      title: "Project Status",
      dataIndex: ["project", "status"],
      width: "10rem",
      sorter: (a, b) =>
        DataTableHelper.sorter(a.project.status, b.project.status),
      filters: ProjectHelper.getStatusFilterOptions(),
      onFilter: (value, record) =>
        DataTableHelper.filter(value, record.project.status),
      render: (status: number) => {
        return (
          <Tag color={ProjectHelper.getStatusColor(status)} key={status}>
            {t(ProjectHelper.getStatusTagName(status))}
          </Tag>
        );
      },
    },
    {
      title: "Project In-Charge",
      dataIndex: ["project", "pic", "name"],
      width: "11.5rem",
      sorter: (a, b) =>
        DataTableHelper.stringSorter(a.project.pic.name, b.project.pic.name),
      filters: ProjectHelper.getPicFilterOptions(pics),
      onFilter: (value, record) =>
        DataTableHelper.filter(value, record.project.pic.id),
    },
    {
      title: "Previous PRUT",
      dataIndex: "previous",
      width: "9.25rem",
      sorter: (a, b) => DataTableHelper.sorter(a.previous, b.previous),
      defaultSortOrder: "descend",
      render: (previous: number) => <Text>{previous}%</Text>,
    },
  ];

  return (
    <div className={styles.modal_content_container}>
      <div className={styles.edit_current_prut_actions}>
        <Button icon={<EditOutlined />} type="primary" onClick={onOpenModal} disabled={!isCurrentWeekdayInRange}>
          {t("Edit Previous PRUT")}
        </Button>
      </div>
      <Table
        columns={projectContributionColumns}
        dataSource={prut.projects}
        rowKey={(record) => record.project.id}
        pagination={false}
      />
      <div className={styles.prut_table_footer_container}>
        <div className={styles.footer_text}>
          <Text strong>{t("Total PRUT")}</Text>
        </div>
        <div className={styles.prut_table_total_percentage}>
          <Text data-testid="total_contribution">
            {prut.totalPreviousContribution}{" "}
          </Text>
          <Text>%</Text>
        </div>
      </div>
    </div>
  );
};

export default PreviousPrutTable;