import { get } from './API';

const fetchPermissions = async () => {
    const response = await get('/user/getPermissions', {});
    return response;
}

const fetchAllContributors = async () => {
    const response = await get('/user/all-contributors', {});
    return response;
}

const fetchContributor = async (id: string) => {
    const response = await get('/user/contributor', { id });
    return response;
};

export { fetchPermissions, fetchAllContributors, fetchContributor };