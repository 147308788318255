import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  ModalProps as AntDModalProps,
  ButtonProps,
  Form,
  Input,
  Select,
  Typography,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import styles from "./CreateProjectModal.module.css";
import { Pic } from "../types/Pic";
import { editProject } from "../api/ProjectAPI";
import { Project, UpdateProjectModalAction } from "../types/Project";
import { ProjectStatus } from "../enum/ProjectStatus";
import { Permissions } from "../enum/Permissions";
import { StatusCode } from "../enum/StatusCode";
import { PermissionHelper } from "../helpers/PermissionHelper";
import useUpdateProjectModal from "../hooks/useUpdateProjectModal";

const { TextArea } = Input;

interface UpdateProjectModalProps extends AntDModalProps {
  pics: Pic[];
  permissions: Array<string>;
  onRefetchData: VoidFunction;
}

const MAX_LENGTH_INPUT = 100;

const MAX_LENGTH_DESCRIPTION = 2000;

const PROJECT_STATUSES = [
  { label: "Ongoing Lead", value: ProjectStatus.ONGOING_LEAD },
  { label: "Ongoing Project", value: ProjectStatus.ONGOING },
  { label: "Completed", value: ProjectStatus.COMPLETED },
];

const UpdateProjectModal = forwardRef<
  UpdateProjectModalAction,
  UpdateProjectModalProps
>(function UpdateProjectModal ({ pics, onRefetchData, permissions }, ref) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [form] = Form.useForm<Omit<Project, "pic"> & { picId: string }>();
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalInfo, setModalInfo] = useState<Project>({ id: '', name: '', pic: { id: '', name: '' }, status: 0 });
  const { submitButtonRef, onModalOk, onFieldsChange, clearTrackingChanges }
    = useUpdateProjectModal({ form, modalInfo, setButtonDisabled});

  const convertProjectInCharge = useMemo(() => {
    return pics.map((item) => ({ label: item.name, value: item.id }));
  }, [pics]);

  const statusesByPermission = useMemo(() => {
    if (!PermissionHelper.hasPermission(Permissions.EDIT_ARCHIVED_PROJECT, permissions)) {
      return PROJECT_STATUSES;
    }
    const clonedProjectStatuses = [...PROJECT_STATUSES];
    clonedProjectStatuses.push({ label: "Archived", value: ProjectStatus.ARCHIVED });
    return clonedProjectStatuses;
  }, [permissions])
  const isProjectTitleDisabled = !PermissionHelper.hasPermission(Permissions.EDIT_ARCHIVED_PROJECT, permissions);

  const onSubmit = async (value: Omit<Project, "pic"> & { picId: string }) => {
    await editProject({ ...value, id: modalInfo.id })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Project details saved!"),
          description: `The project details for ${value.name} is now updated.`,
          className: styles.alert_success,
        });
        onRefetchData();
        close();
      })
      .catch((error) => {
        const { status, data } = error.response;
        switch (status) {
          case StatusCode.CONFLICT:
            form.setFields([
              {
                name: "name",
                errors: [data.message],
              },
            ]);
            break;
          case StatusCode.UNPROCESSABLE_CONTENT:
            form.setFields([
              {
                name: "status",
                errors: [data.message],
              },
            ]);
            break;
          default:
            notification.open({
              type: "error",
              placement: "topRight",
              top: 84,
              message: t("Action Failed"),
              description: t(
                "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
              ),
              className: styles.alert_error,
            });
            break;
        }
      }).finally(() => {
        clearTrackingChanges();
      });
  };

  const open = useCallback((info: Project) => {
    setModalInfo(info);
    form.setFields(
      Object.entries(info).map(([key, value]) => ({
        name: key === 'pic' ? 'picId' : key,
        value: key === 'pic' ? (value as Pic).id : value,
      }))
    );
    setOpenModal(true);
  }, [form]);

  const close = useCallback(() => {
    setOpenModal(false);
    clearTrackingChanges();
    form.resetFields();
  }, [form]);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Modal
      title={<Title level={5}>Edit Project Details</Title>}
      data-testid="create_project_modal"
      open={openModal}
      onOk={onModalOk}
      onCancel={close}
      okText={t("Save Changes")}
      okButtonProps={{ "data-testid": "button-submit", disabled: buttonDisabled } as ButtonProps}
      size={"medium"}
      destroyOnClose
    >
      <div className={styles.modal_content_container}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onSubmit}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            label={t("Project Title")}
            name="name"
            rules={[
              { required: true, message: "This is a required field.", validateTrigger: [] },
              {
                whitespace: true, message: 'Please enter at least one non-space character.', validateTrigger: []
              },
            ]}
          >
            <Input
              data-testid="current_value_input"
              placeholder={t("Enter Project Title")}
              maxLength={MAX_LENGTH_INPUT}
              disabled={isProjectTitleDisabled}
            />
          </Form.Item>

          <Form.Item
            label={t("Project Status")}
            name="status"
            rules={[{ required: true, message: "This is a required field." }]}
          >
            <Select
              className={styles.input}
              placeholder={t("Select Project Status")}
              options={statusesByPermission}
            />
          </Form.Item>

          <Form.Item
            label={t("Project In-Charge")}
            name="picId"
            rules={[{ required: true, message: "This is a required field." }]}
          >
            <Select
              className={styles.input}
              placeholder={t("Select Project In-Charge")}
              options={convertProjectInCharge}
              disabled={isProjectTitleDisabled}
            />
          </Form.Item>

          <Form.Item label={t("Description")} name="description">
            <TextArea
              data-testid="current_value_description"
              className={styles.description_input}
              placeholder={t("Enter project description")}
              maxLength={MAX_LENGTH_DESCRIPTION}
              rows={5}
              showCount
            />
          </Form.Item>

          <input data-testid="submit-form" ref={submitButtonRef} type="submit" hidden />
        </Form>
      </div>
    </Modal>
  );
});

export default UpdateProjectModal;
