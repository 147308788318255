import { fetchPermissions } from "../api/UserAPI";

const hasPermission = (permission: string, userPermissions: Array<string>) => {
    return userPermissions.includes(permission);
};

const getPermissions = async (): Promise<Array<string>> => {
    const response = await fetchPermissions();
    return response.data.permissions;
}

export const PermissionHelper = {
    hasPermission,
    getPermissions
}