import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as ImportedRoute,
  Outlet,
  Navigate,
} from "react-router-dom";
import { 
  AllProjects, 
  Error, 
  ProjectDetails, 
  AllContributors, 
  Login, 
  AboutIncentives, 
  Logout, 
  QuarterlyBonus, 
  ContributorDetails, 
  MyPrut
} from "../pages";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import Loader from "../components/Loader";

const RoutesComponent = () => {
  const userLoggedIn = useIsAuthenticated();
  const { inProgress } = useMsal();

  const ProtectedRoutes = () => {
    return (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.Logout) ? <Loader /> 
    : userLoggedIn ? <Outlet /> : <Navigate to="/error/unauthorised" replace />
  };

  function UnprotectedRoute() {
    return userLoggedIn ? <Navigate to="/" /> : <Outlet />;
  }

  return (
    <Router>
        <ImportedRoute>
          <Route element={<UnprotectedRoute />}>
            <Route path="/:tenantId/login" element={<Login />}/>
            <Route path="/logout" element={<Logout />}/>
            <Route path="/error" element={<Error errorType="not-found"/>} />
            <Route path="/error/oops" element={<Error errorType="oops"/>} />
            <Route path="/error/unauthorised" element={<Error errorType="unauthorised"/>} />
          </Route>
          <Route element={<ProtectedRoutes />}>
  	        <Route path="/" element={<QuarterlyBonus />} />
            <Route path="/projects" element={<AllProjects />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/contributors" element={<AllContributors />} />
            <Route path="/contributors/:id" element={<ContributorDetails />} />
            <Route path="/about-incentives" element={<AboutIncentives />}/>
            <Route path="/my-prut" element={<MyPrut />} />
          </Route>
          <Route path="*" element={<Navigate to="/error" replace/>} />
        </ImportedRoute>
    </Router>
  );
};

export default RoutesComponent;
