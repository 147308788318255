const sorter = (a: any, b: any) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}

const filter = (searchValue: any, data: any) => {
    return data === searchValue;
}

const stringSorter = (a: string, b: string) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return sorter(a, b);
}


export const DataTableHelper = {
    sorter,
    filter,
    stringSorter
}