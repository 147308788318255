import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { ModalProps as AntDModalProps, ButtonProps, Typography, notification } from "antd";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import styles from "./CreateProjectModal.module.css";
import { deleteContributor } from "../api/ContributorAPI";
import { ContributorModalInfo, ModalAction } from "../types/Contributors";
import { Project } from "../types/Project";

interface Props extends AntDModalProps {
  project: Project;
  onRefetchData?: (reloadTable: boolean) => void;
}

const ContributorDeleteConfirmationModal = forwardRef<
  ModalAction<ContributorModalInfo>,
  Props
>(function ContributorDeleteConfirmationModalInner ({ project, onRefetchData }, ref) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contributor, setContributor] = useState<ContributorModalInfo>({
    id: "",
    upn: "",
  });

  const onSubmit = async () => {
    setIsLoading(true);
    await deleteContributor({
      projectId: project.id,
      contributorId: contributor.id,
    })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Contributor Removed!"),
          description: `${contributor.upn} has been successfully removed from ${project.name}`,
          className: styles.alert_success,
        });
        onRefetchData?.(true);
        close();
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: "notification-error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onModalOk = () => {
    onSubmit();
  };

  const open = useCallback((info: ContributorModalInfo) => {
    setContributor(info);
    setOpenModal(true);
  }, []);

  const close = useCallback(() => {
    setOpenModal(false);
  }, []);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Modal
      title={<Title level={5}>{t("Delete Contributor")}</Title>}
      data-testid="create_project_modal"
      open={openModal}
      onOk={onModalOk}
      onCancel={close}
      okText={t("Yes, Delete")}
      okButtonProps={{ "data-testid": "button-submit" } as ButtonProps}
      size={"medium"}
      destroyOnClose
      confirmLoading={isLoading}
    >
      <div className={styles.modal_content_container}>
        Are you sure you want to remove {contributor.upn} from this project?
        Their PRUT % will be deleted from the current cycle and they will still
        be able to view their previous PRUT. You can still edit their previous
        PRUT via the ‘All Contributors’ or the ‘Contributor Details’ page.
      </div>
    </Modal>
  );
});

export default ContributorDeleteConfirmationModal;
