import React, { useEffect, useState } from "react";
import { Button, Table, Typography } from "antd";
import type { TableColumnsType } from "antd";
import { DataTableHelper } from "../helpers/DataTableHelper";
import { ContributorPrut } from "../types/Prut";
import { useTranslation } from "react-i18next";
import Search from "antd/lib/input/Search";
import styles from "./PrutContributorsTable.module.css";
import { ProjectStatus } from "../enum/ProjectStatus";
import PrutModal from "./PrutModal";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import AddContributorModal from "./AddContributorModal";
import { Project } from "../types/Project";
import { PermissionHelper } from "../helpers/PermissionHelper";
import { Permissions } from "../enum/Permissions";
import usePrutContributorTable from "../hooks/usePrutContributorTable";
import ContributorDeleteConfirmationModal from "./ContributorDeleteConfirmationModal";
interface ProjectContributorsTableProps {
  project: Project;
  contributors: ContributorPrut[];
  permissions: Array<string>;
  addContributorDisabled: boolean;
  setReloadTable: (reloadTable: boolean) => void;
}

const ProjectContributorsTable: React.FC<ProjectContributorsTableProps> = ({
  project,
  contributors,
  permissions,
  setReloadTable,
  addContributorDisabled
}) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [upn, setUpn] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const [filteredContributors, setFilteredContributors] = useState<
    ContributorPrut[]
  >([]);

  const [status, setStatus] = useState<number>(0);
  const {
    addContributorRef,
    deleteContributorRef,
    onOpenAddContributorModal,
    onOpenContributorDeleteConfirmationModal,
  } = usePrutContributorTable({ project });
  const { status: projectStatus } = project;

  useEffect(() => {
    setFilteredContributors(contributors);
  }, [contributors, setReloadTable]);

  useEffect(() => {
    setStatus(projectStatus);
  }, [projectStatus]);

  const columns: TableColumnsType<ContributorPrut> = [
    {
      title: "Contributor's Name",
      dataIndex: "upn",
      width: "31.25rem",
      sorter: (a, b) => DataTableHelper.sorter(a.upn, b.upn),
      render: (upn: string, contributor: ContributorPrut) => (
        <Text
          className={styles.contributors_name}
          onClick={() => navigate(`/contributors/${contributor.id}`)}
        >
          {upn}{!contributor.active && ' (inactive)'}
        </Text>
      ),
    },
    {
      title: "Previous PRUT",
      dataIndex: "previous",
      sorter: (a, b) => DataTableHelper.sorter(a.previous, b.previous),
      render: (previous: number) => <Text>{previous}%</Text>,
    },
    {
      title: "Current PRUT",
      dataIndex: "current",
      defaultSortOrder: "descend",
      sorter: (a, b) => DataTableHelper.sorter(a.current, b.current),
      render: (current: number) => <Text>{current}%</Text>,
    },
    {
      title: "Action(s)",
      key: "actions",
      render: (_, record) => (
        <div className={styles.actions}>
          <Button
            className={styles.no_padding}
            type="link"
            disabled={status === ProjectStatus.ARCHIVED}
            onClick={() => {
              setUserId(record.id);
              setUpn(`${record.upn}${record.active ? "" : " (inactive)"}`);
              setShowModal(true);
            }}
          >
            {t("Edit Current PRUT")}
          </Button>
          <div className={styles.dividerVertical}></div>
          <Button
            className={styles.no_padding}
            type="link"
            danger
            disabled={status === ProjectStatus.ARCHIVED}
            onClick={() => onOpenContributorDeleteConfirmationModal(record)}
          >
            {t("Delete")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={styles.search_wrapper}>
        <Search
          className={styles.search}
          placeholder={t("Search Contributor's Name")}
          allowClear
          onSearch={(value) =>
            setFilteredContributors(
              contributors.filter((contributor) =>
                contributor.upn
                  .toLowerCase()
                  .includes(value.toLocaleLowerCase())
              )
            )
          }
        />
        {PermissionHelper.hasPermission(Permissions.CONTRIBUTOR_ADD, permissions)
          &&
          (<Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onOpenAddContributorModal}
            disabled={addContributorDisabled}
          >
            {t("Add Contributor")}
          </Button>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={filteredContributors}
        rowKey="id"
        pagination={{ defaultPageSize: 5, pageSize: 5 }}
      />
      <PrutModal
        id={userId}
        showModal={showModal}
        upn={upn}
        onCloseModal={() => {
          setUserId(""); // turn off the userid first to trigger another fetch in the modal
          setShowModal(false);
        }}
        setReloadTable={setReloadTable}
      />

      <ContributorDeleteConfirmationModal
        ref={deleteContributorRef}
        project={project}
        onRefetchData={setReloadTable}
      />

      <AddContributorModal
        ref={addContributorRef}
        onRefetchData={setReloadTable}
      />
    </>
  );
};

export default ProjectContributorsTable;
