import moment from "moment";
import { Weekday } from "../enum/Weekday";

const formatDate = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
};

const formatDateWithTime = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}, ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

const getCurrentWeekNumber = (date: Date): string => {
    const dayOfWeek = date.getDay();
    const dayOfWeekNumber = dayOfWeek === 0 ? 7 : dayOfWeek; // Ensure Sunday is considered as 7
    date.setDate(date.getDate() + 4 - dayOfWeekNumber);
    const yearStart = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((date.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    return weekNumber < 10 ? `0${weekNumber}` : weekNumber.toString();
};

const getLastWeekNumber = (date: Date): string => {
    const currentWeekNumber = getCurrentWeekNumber(date);
    let lastWeekNumber = parseInt(currentWeekNumber, 10) - 1;

    if (lastWeekNumber === 0) { // If the previous week is the last week of the previous year
        const lastYear = date.getFullYear() - 1;
        const lastYearStart = new Date(lastYear, 0, 1);
        const lastYearWeeks = Math.ceil((date.getTime() - lastYearStart.getTime()) / (7 * 24 * 60 * 60 * 1000));
        lastWeekNumber = lastYearWeeks; // Set last week number to the total weeks of last year
    }

    return lastWeekNumber < 10 ? `0${lastWeekNumber}` : lastWeekNumber.toString();
};

const getCurrentWeekRange = (date: Date): string => {
    date.setDate(date.getDate() + 2); // Assuming the date in argument is start date of week (Monday) then shifting it to Wednesday first
    const dayOfWeek = date.getDay(); // Get the current day of the week
    const diffToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Calculate the difference to Monday
  
    // Set the date to the previous Monday
    date.setDate(date.getDate() - diffToMonday);
    const monday = new Date(date);
  
    // Set the date to the following Sunday
    date.setDate(date.getDate() + 6);
    const sunday = new Date(date);
  
    return `${formatDate(monday)} - ${formatDate(sunday)}`;
};

const getNextSundayInDate = (date: Date): Date => {
    const dayOfWeek = date.getDay(); // Get the current day of the week
    const diffToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Calculate the difference to Monday
  
    // Set the date to the previous Monday
    date.setDate(date.getDate() - diffToMonday);
  
    // Set the date to the following Sunday
    date.setDate(date.getDate() + 6);
    const sunday = new Date(date);
  
    // Determine the next Sunday
    const nextSunday = new Date(sunday.getTime() + 7 * 24 * 60 * 60 * 1000); // Add the calculated number of milliseconds to the current date
    nextSunday.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999
    
    return nextSunday;
};

const getNextSunday = (date: Date): string => {
    // Determine the next Sunday
    const nextSunday = getNextSundayInDate(date);
    
    return `${formatDateWithTime(nextSunday)}`;
};

function getMondayOfWeek(weekNumber: number, year: number) {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7); // Set date to the first day of the specified week number
    const dayOfWeek = date.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Calculate the difference to Monday
    return new Date(date.setDate(diff));
}

function formatWeekNumber(weekNumber: number) {
    return weekNumber < 10 ? `0${weekNumber}` : weekNumber.toString();
}

function generatePayoutPeriods() {
    const startingYear = 2023;
    const periods: {label: string, value: string}[] = [];
    for (let i = 0; i < 5; i++) {
        const temp1 = `Jan ${startingYear + i} to Mar ${startingYear + i}`;
        const temp2 = `Apr ${startingYear + i} to Jun ${startingYear + i}`;
        const temp3 = `Jul ${startingYear + i} to Sep ${startingYear + i}`;
        const temp4 = `Oct ${startingYear + i} to Dec ${startingYear + i}`;
        periods.push(
            {label: temp1, value: temp1}, 
            {label: temp2, value: temp2}, 
            {label: temp3, value: temp3}, 
            {label: temp4, value: temp4}
        );
    }
    return periods;
}

function formatDateTimeWithoutComma(date: Date) {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
}

function isCurrentWeekdayInRange(startWeekday: Weekday, endWeekday: Weekday) {
    const now = new Date();
    const currentWeekday = now.getDay();
    if (currentWeekday >= startWeekday && currentWeekday <= endWeekday) {
        return true;
    }
    return false;
}

const getWeekday = (date: Date, weekday: Weekday): string => {
    const dayOfWeek = date.getDay(); // Get the current day of the week
    const diffToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Calculate the difference to Monday
    const diffFromMondayToWeekday = (weekday === 0 ? 6 : weekday - 1); // Calculate the difference to Monday
  
    // Set the date to the previous Monday
    date.setDate(date.getDate() - diffToMonday);
  
    // Set the date to the following weekday
    date.setDate(date.getDate() + diffFromMondayToWeekday);
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999
   
    return `${formatDateWithTime(newDate)}`;
};
 

export const DateHelper = {
    getCurrentWeekNumber,
    formatDate,
    formatDateWithTime,
    getCurrentWeekRange,
    getNextSundayInDate,
    getNextSunday,
    getLastWeekNumber,
    getMondayOfWeek,
    formatWeekNumber,
    generatePayoutPeriods,
    formatDateTimeWithoutComma,
    isCurrentWeekdayInRange,
    getWeekday
};