import React from "react";
import { Table, TableColumnsType, Typography } from "antd";
import { ChangeLog, ChangeLogPagination, Project } from "../types/Project";
import { ChangeLogAttribute } from "../enum/ChangeLogAttribute";
import { DateHelper } from "../helpers/DateHelper";
import { ProjectHelper } from "../helpers/ProjectHelper";
import { useTranslation } from "react-i18next";
import styles from './ProjectChangeLog.module.css';
import { ChangeLogAction } from "../enum/ChangeLogAction";

interface ProjectChangeLogProps {
  project: Project;
  changeLog: ChangeLogPagination;
  setPagination: React.Dispatch<{ pageIndex: number, pageSize: number }>;
}

const ProjectChangeLog = ({ project, changeLog, setPagination }: ProjectChangeLogProps) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const getChangeLogAttribute = (attribute: string, action?: ChangeLogAction) => {
    if (action === ChangeLogAction.EDIT) {
      return `edited ${attribute} to`;
    }
    return `added ${attribute}`;
  }

  const getProjectContributorLogByAction = (record: ChangeLog) => {
    const { authorEmail, other } = record;
    const {
      contributorEmail,
      week,
      startDate = "",
      endDate = "",
      action,
    } = other ?? {};
    const newStartDate = DateHelper.formatDate(new Date(startDate));
    const newEndDate = DateHelper.formatDate(new Date(endDate));
    switch (action) {
      case ChangeLogAction.DELETE:
        return (
          <span>
            {authorEmail} removed <b>{contributorEmail}</b> from project: &apos;
            <b>{project.name}</b>&apos; and their utilisation for{" "}
            <b>
              Week {week} ({newStartDate} - {newEndDate})
            </b>{" "}
            to: &apos;<b>0%</b>&apos;.
          </span>
        );
      default:
        return (
          <span>
            {authorEmail} added <b>{contributorEmail}</b> to project: &apos;
            <b>{project.name}</b>&apos;.
          </span>
        );
    }
  };

  const columns: TableColumnsType<ChangeLog> = [
    {
      title: "Date/Time",
      dataIndex: "timestamp",
      width: "178px",
      className: styles.vertical_top,
      render: (upn: Date) => (
        <Text>{DateHelper.formatDateTimeWithoutComma(new Date(upn))}</Text>
      ),
    },
    {
      title: "Change Made",
      className: styles.table_content,
      render: (_, record: ChangeLog) => {
        const { authorEmail, attribute, other, newValue } = record;
        const {
          contributorEmail,
          week,
          startDate = "",
          endDate = "",
          name,
          action
        } = other ?? {};
        const newStartDate = DateHelper.formatDate(new Date(startDate));
        const newEndDate = DateHelper.formatDate(new Date(endDate));
        switch (attribute) {
          case ChangeLogAttribute.PERCENTAGE:
            return (
              <span>
                {authorEmail} edited <b>{contributorEmail}’s</b> utilisation for{" "}
                <b>
                  Week {week} ({newStartDate} - {newEndDate})
                </b>{" "}
                to: &apos;<b>{newValue}%</b>&apos;.
              </span>
            );
          case ChangeLogAttribute.NAME:
            return (
              <span>
                {authorEmail} {getChangeLogAttribute("project title", action)}: &apos;<b>{newValue}</b>&apos;.
              </span>
            );
          case ChangeLogAttribute.PIC:
            return (
              <span>
                {authorEmail} {getChangeLogAttribute("project in-charge", action)}: &apos;<b>{name}</b>&apos;.
              </span>
            );
          case ChangeLogAttribute.DESC:
            return (
              <span>
                {authorEmail} {getChangeLogAttribute("description", action)}: &apos;<b>{newValue}</b>&apos;.
              </span>
            );
          case ChangeLogAttribute.CONTRIBUTOR:
            return getProjectContributorLogByAction(record);
          default:
            return (
              <span>
                {authorEmail} {getChangeLogAttribute("project status", action)}: &apos;<b>{t(ProjectHelper.getStatusTagName(Number(newValue)))}</b>&apos;.
              </span>
            );
        }
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={changeLog.changelog}
        rowKey="id"
        pagination={{
          defaultPageSize: 5,
          pageSize: changeLog.meta.limit,
          total: changeLog.meta.total,
          pageSizeOptions: [5, 10, 20, 30, 40, 50],
          onChange(page, pageSize) {
            setPagination({ pageIndex: (page - 1) * pageSize, pageSize });
          },
        }}
      />
    </div>
  );
};

export default ProjectChangeLog;
