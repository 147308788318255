import { Button, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { DataTableHelper } from "../helpers/DataTableHelper";
import { ProjectHelper } from "../helpers/ProjectHelper";
import { ProjectStatus } from "../enum/ProjectStatus";
import { Pic } from "../types/Pic";
import styles from './AllProjectsTable.module.css';
import { PermissionHelper } from "../helpers/PermissionHelper";
import { Project } from "../types/Project";
import { Permissions } from "../enum/Permissions";
import { useNavigate } from "react-router-dom";

type AllProjectsTableProps = {
    permissions: Array<string>;
    pics: Pic[];
    projects: Project[];
    loading: boolean;
    onOpenEditModal?: (project: Project) => void;
    onOpenAddContributor?: (project: Project) => void;
}

const AllProjectsTable: React.FC<AllProjectsTableProps> = ({ permissions, pics, projects, loading, onOpenEditModal, onOpenAddContributor }) => {
    const { t } = useTranslation();
    const { Text } = Typography;
    const navigate = useNavigate();

    const columns: ColumnsType<Project> = [
        {
            title: t('Project Title'),
            dataIndex: 'name',
            width: '31.25rem',
            sorter: (a, b) => DataTableHelper.stringSorter(a.name, b.name),
            render: (text: string, record: Project) => {
                return (
                    <Text className={styles.project_title} onClick={() => navigate(`/projects/${record.id}`)}>{text}</Text>
                )
            }
        },
        {
            title: t('Project Status'),
            dataIndex: 'status',
            sorter: (a, b) => ProjectHelper.statusSorter(a.status, b.status),
            defaultSortOrder: 'ascend',
            filters: ProjectHelper.getStatusFilterOptions(),
            onFilter: (value, record) => DataTableHelper.filter(value, record.status),
            render: (status: number) => {
                return (
                    <Tag color={ProjectHelper.getStatusColor(status)} key={status}>
                        {t(ProjectHelper.getStatusTagName(status))}
                    </Tag>
                );
            }
        },
        {
            title: t('Project In-Charge'),
            dataIndex: ['pic', 'name'],
            sorter: (a, b) => DataTableHelper.stringSorter(a.pic.name, b.pic.name),
            filters: ProjectHelper.getPicFilterOptions(pics),
            onFilter: (value, record) => DataTableHelper.filter(value, record.pic.id)
        },
    ];

    const hasEditArchivedProjectPermission = PermissionHelper.hasPermission(Permissions.EDIT_ARCHIVED_PROJECT, permissions);
    const hasEditProjectPermission = PermissionHelper.hasPermission(Permissions.EDIT_PROJECT, permissions);
    const hasAddContributorPermission = PermissionHelper.hasPermission(Permissions.CONTRIBUTOR_ADD, permissions)

    const actionsColumn = {
        title: t('Action(s)'),
        dataIndex: 'status',
        render: (status: number, record: Project) => {
            const isEditProjectLinkDisabled = !hasEditArchivedProjectPermission && status === ProjectStatus.ARCHIVED;
            const isAddContributorLinkDisabled = status === ProjectStatus.ARCHIVED;
            return (
                <div className={styles.actions_column}>
                    {hasEditProjectPermission && (
                        <Button
                            onClick={() => onOpenEditModal?.(record)}
                            className={styles.no_padding}
                            type="link"
                            disabled={isEditProjectLinkDisabled}
                        >
                            {t("Edit Project Details")}
                        </Button>
                    )}
                    {hasAddContributorPermission && (
                        <Button
                            onClick={() => onOpenAddContributor?.(record)}
                            className={styles.no_padding}
                            type="link"
                            disabled={isAddContributorLinkDisabled}
                        >
                            {t("Add Contributor ")}
                        </Button>
                    )}
                </div>
            );
        }
    };

    const shouldDisplayActionColumn = hasEditProjectPermission || hasAddContributorPermission;
    if (shouldDisplayActionColumn) {
        columns.push(actionsColumn);
    }

    return (
        <Table loading={loading} columns={columns} dataSource={projects} pagination={false} rowKey="id" />
    );
}

export default AllProjectsTable;