import React, { useMemo } from "react";
import {
  ModalProps as AntDModalProps,
  ButtonProps,
  Form,
  Input,
  Select,
  Typography,
} from "antd";
import Modal from "./Modal";
import styles from "./CreateProjectModal.module.css";
import { Pic } from "../types/Pic";
import { ProjectStatus } from "../enum/ProjectStatus";
import { useCreateProjectModal } from "../hooks";
import { useTranslation } from "react-i18next";
import { ProjectFormField } from "../types/Project";

const { TextArea } = Input;

interface CreateProjectModalProps extends AntDModalProps {
  showModal: boolean;
  pics: Pic[];
  onRefetchData: VoidFunction;
  onClose: VoidFunction;
}

const MAX_LENGTH_INPUT = 100;

const MAX_LENGTH_DESCRIPTION = 2000;

const PROJECT_STATUSES = [
  { label: "Ongoing Lead", value: ProjectStatus.ONGOING_LEAD },
  { label: "Ongoing Project", value: ProjectStatus.ONGOING },
];

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  showModal,
  pics,
  onRefetchData,
  onClose,
}) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [form] = Form.useForm<ProjectFormField>();
  const {
    submitButtonRef,
    buttonDisabled,
    setButtonDisabled,
    onModalOk,
    onCloseModal,
    onSubmit,
  } = useCreateProjectModal({ t, form, onRefetchData, onClose });

  const convertProjectInCharge = useMemo(() => {
    return pics.map((item) => ({ label: item.name, value: item.id }));
  }, [pics]);

  const onFieldChange = () => {
    setButtonDisabled(
      form.getFieldsError().some((field) => field.errors.length > 0)
    )
  }

  return (
    <Modal
      title={<Title level={5}>Create Project</Title>}
      data-testid="create_project_modal"
      open={showModal}
      onOk={onModalOk}
      onCancel={onCloseModal}
      okText={t("Create")}
      okButtonProps={
        { "data-testid": "button-submit", disabled: buttonDisabled } as ButtonProps
      }
      size={"medium"}
      destroyOnClose
    >
      <div className={styles.modal_content_container}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onSubmit}
          onFieldsChange={onFieldChange}
        >
          <Form.Item
            label={t("Project Title")}
            name="name"
            rules={[
              { required: true, message: "This is a required field.", validateTrigger: [] },
              {
                whitespace: true, message: 'Please enter at least one non-space character.', validateTrigger: []
              },
            ]}
          >
            <Input
              data-testid="current_value_input"
              placeholder={t("Enter Project Title")}
              maxLength={MAX_LENGTH_INPUT}
            />
          </Form.Item>

          <Form.Item
            label={t("Project Status")}
            name="status"
            rules={[{ required: true, message: "This is a required field." }]}
          >
            <Select
              className={styles.input}
              placeholder={t("Select Project Status")}
              options={PROJECT_STATUSES}
            />
          </Form.Item>

          <Form.Item
            label={t("Project In-Charge")}
            name="picId"
            rules={[{ required: true, message: "This is a required field." }]}
          >
            <Select
              className={styles.input}
              placeholder={t("Select Project In-Charge")}
              options={convertProjectInCharge}
            />
          </Form.Item>

          <Form.Item label={t("Description")} name="description">
            <TextArea
              data-testid="current_value_description"
              className={styles.description_input}
              placeholder={t("Enter project description")}
              maxLength={MAX_LENGTH_DESCRIPTION}
              rows={5}
              showCount
            />
          </Form.Item>

          <input data-testid="submit-form" ref={submitButtonRef} type="submit" hidden />
        </Form>
      </div>
    </Modal>
  );
};

export default CreateProjectModal;
