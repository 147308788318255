import React, { useEffect, useState } from 'react';

import DefaultLayout from '../layout/DefaultLayout'
import Loader from '../components/Loader';
import AllContributorsTable from '../components/AllContributorsTable';
import { Button, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import styles from "./AllContributors.module.css";
import { PermissionHelper } from '../helpers/PermissionHelper';
import { Permissions } from '../enum/Permissions';
import { fetchLastSyncHistory, syncContributors } from '../api/GraphAPI';
import { useNavigate } from 'react-router-dom';
import { DateHelper } from '../helpers/DateHelper';

type SyncHistory = {
    timeSynced: string;
    usersSynced: string;
}

const AllContributors: React.FC = () => {
    const { t } = useTranslation();
    const { Title, Text } = Typography;
    const navigate = useNavigate();
    const [syncing, setSyncing] = useState(false);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [syncHistory, setSyncHistory] = useState<SyncHistory>({ timeSynced: '', usersSynced: '' });
    
    useEffect(() => {
        PermissionHelper.getPermissions().then((response) => {
            setPermissions(response);
        });
    }, []);

    useEffect(() => {
        if (!permissions || permissions.length === 0) return;
        
        // We only allow fetching of last sync history if the user has the permission to sync contributors
        if (PermissionHelper.hasPermission(Permissions.SYNC_CONTRIBUTOR, permissions)) {
            fetchLastSyncHistory().then((response) => {
                setSyncHistory(response.data.latestHistory);
            });
        }
    }, [syncing, permissions]);

    useEffect(() => {
        if (!permissions || permissions.length === 0) return;

        if (!PermissionHelper.hasPermission(Permissions.CONTRIBUTOR_LIST, permissions)) {
            navigate('/error/unauthorised')
        }

    }, [permissions, navigate]);

    const handleSyncContributors = async () => {
        setSyncing(true);
        await syncContributors().then((response) => {
            if (response.status === "success") {
                notification.open({
                    type: "success",
                    placement: "topRight",
                    top: 84,
                    message: "Contributor list synced!",
                    description: `The contributor list has been successfully synced with Microsoft 365.`,
                    className: styles.alert_success
                });
            }
        })
        .catch(() => {
            notification.open({
                type: "error",
                placement: "topRight",
                top: 84,
                message: "Action Failed",
                description:
                  "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists",
                className: styles.alert_error,
              });
        });
        setSyncing(false);
    }

    return (
        <DefaultLayout>
            <div className={styles.page_container}>
                <div>
                    <Title level={4}>{t('All Contributors')}</Title>
                </div>
                {PermissionHelper.hasPermission(Permissions.SYNC_CONTRIBUTOR, permissions) &&
                    <div className={styles.button_container}>
                        <Button
                            type="primary"
                            icon={<SyncOutlined />}
                            onClick={handleSyncContributors}
                            loading={syncing}
                        >
                            {syncing ? t('Syncing in progress...') : t('Sync Contributors List')}
                        </Button>
                        <Text type='secondary'>
                            Last synced: {(syncHistory.timeSynced === "-" || syncHistory.timeSynced.length === 0) ? "-" : DateHelper.formatDateWithTime(new Date(syncHistory.timeSynced))} | No. of users retrieved: {syncHistory.usersSynced}
                        </Text>
                    </div>
                }

                <div>
                    {syncing ?
                        <Loader /> :
                        <AllContributorsTable />
                    }
                </div>
            </div>
        </DefaultLayout>
    );
}

export default AllContributors;