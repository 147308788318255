import { ConfigHelper } from "../helpers/ConfigHelper"; // TODO: Temporarily until we have the parameter store up

export const bonusStaticInfo = {
    quarterlyBonusPerPerson: {
        tooltip: {
            text: "The payable quarterly bonus to be paid out to each person",
            formula: ConfigHelper.getConfig("REACT_APP_BONUS_STATIC_PER_PERSON", "")
        }
    },
    totalRevenue: {
        tooltip: {
            text: "The total income generated within the quarterly bonus period"
        }
    },
    totalCollection: {
        tooltip: {
            text: "The total cash collected from customers within the quarterly bonus period"
        }
    },
    collectionRatio: {
        tooltip: {
            text: "The ratio of cash collected vs income generated",
            formula: ConfigHelper.getConfig("REACT_APP_BONUS_STATIC_COLLECTION_RATIO", "")
        }
    },
    overallProjectBudget: {
        tooltip: {
            text: "The budget for customers’ project cost within the quarterly bonus period",
            formula: ConfigHelper.getConfig("REACT_APP_BONUS_STATIC_PROJECT_BUDGET", "")
        }
    },
    overallCostOfRevenue: {
        tooltip: {
            text: "The actual cost involved in directly generating the Total Revenue, mainly salaries, licenses & sub-contractor cost"
        }
    },
    totalForecastedQuarterlyBonus: {
        tooltip: {
            text: "The estimated quarterly bonus pool to be shared by all eligible persons",
            formula: ConfigHelper.getConfig("REACT_APP_BONUS_STATIC_TOTAL_FORECASTED", "")
        }
    },
    totalPayableQuarterlyBonus: {
        tooltip: {
            text: "The actual quarterly bonus pool, determined by cash collection (if Total Forecasted Quarterly Bonus is &gt;$0) and after deducting administrative expenses",
            formula: ConfigHelper.getConfig("REACT_APP_BONUS_STATIC_TOTAL_PAYABLE", "")
        }
    },
    totalEligiblePersons: {
        tooltip: {
            text: "The number of persons who meet the eligibility criteria for the Operator/Overwatch Bonus Policy within the quarterly bonus period"
        }
    }
};