import React from "react";
import { Divider, Typography, Button } from "antd";
import LoginLayout from "../layout/LoginLayout";
import { useNavigate } from "react-router-dom";
import { StorageHelper } from "../helpers/StorageHelper";

const Logout: React.FC = () => {
  const { Title } = Typography;
  const tenantConfig = StorageHelper.getTenantConfig();

  const navigate = useNavigate();

  return (
    <LoginLayout>
      <div>
        <Title level={5}>You have successfully logged out.</Title>
      </div>
      <Divider />
      <Button type="primary" size="large" onClick={() => {navigate(`${tenantConfig?.tenantId === undefined ? '' : '/' + tenantConfig.tenantId}/login`)}}>
        Back to Login
      </Button>
    </LoginLayout>
  );
};

export default Logout;
