import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import styles from './LoginLayout.module.css';

import { Image } from "antd";
import Logo from '../assets/p1tstop-logo.svg';

interface LogingLayoutProps {
    children: ReactNode;
}

const LoginLayout: React.FC<LogingLayoutProps> = ({ children }) => {
    return (
        <Layout className={styles.layout_container}>
            <div className={styles.login_card}>
                <Image
                    preview={false}
                    src={Logo}
                    alt="logo"
                    className={styles.image}
                />
                <div>
                   {children}
                </div>
            </div>
        </Layout>
    );
}

export default LoginLayout;
