import React from 'react';
import { Spin } from 'antd';
import styles from './Loader.module.css';

interface LoaderProps {
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({className}) => {
  return (
      <div className={className ?? styles.spinner_container}>
        <Spin size="large"/>
      </div>
  );
};

export default Loader;