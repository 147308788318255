import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb, { BreadcrumbItem } from "../components/BreadCrumb";
import { Button, Card, Tag, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import styles from "./ProjectDetails.module.css";
import Loader from "../components/Loader";
import { PermissionHelper } from "../helpers/PermissionHelper";
import { Permissions } from "../enum/Permissions";
import { ProjectHelper } from "../helpers/ProjectHelper";
import { fetchChangeLog, fetchProject } from "../api/ProjectAPI";
import { ChangeLogPagination, Project } from "../types/Project";
import CurrentPrutBanner from "../components/CurrentPrutBanner";
import ProjectContributorsTable from "../components/PrutContributorsTable";
import { PrutByProject } from "../types/Prut";
import { fetchPrutByProject } from "../api/PrutAPI";
import ProjectChangeLog from "../components/ProjectChangeLog";
import UpdateProjectModal from "../components/UpdateProjectModal";
import { fetchPics } from "../api/PicAPI";
import { Pic } from "../types/Pic";
import { ProjectStatus } from "../enum/ProjectStatus";
import { useProjectDetail } from "../hooks";

const ProjectDetails: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const [changeLog, setChangeLog] = React.useState<ChangeLogPagination>({
    changelog: [],
    meta: { offset: 0, limit: 5, total: 5 },
  });
  const [permissions, setPermissions] = React.useState<Array<string>>([]);
  const [project, setProject] = React.useState<Project>({
    id: "",
    name: "",
    status: 0,
    pic: { id: "", name: "" },
  });
  const [prut, setPrut] = React.useState<PrutByProject>({
    currentWeekNumber: 0,
    currentWeekYear: 0,
    currentWeekStartDate: "",
    currentWeekEndDate: "",
    previousWeekNumber: 0,
    previousWeekYear: 0,
    contributors: [],
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const [pics, setPics] = useState<Array<Pic>>([]);
  const { editModalRef, reloadTable, setReloadTable, onOpenEditProject } = useProjectDetail({ project });

  const isEditProjectButtonDisabled = useMemo(() => {
    const hasEditArchivedProjectPermission = PermissionHelper.hasPermission(Permissions.EDIT_ARCHIVED_PROJECT, permissions);
    return !hasEditArchivedProjectPermission && project.status === ProjectStatus.ARCHIVED;
  }, [permissions, project.status]);
  const isAddContributorButtonDisabled = project.status === ProjectStatus.ARCHIVED;

  const breadcrumbItems: BreadcrumbItem[] = [
    { path: "/projects", name: "All Projects" },
    { path: "", name: project.name },
  ];

  const fetchData = useCallback(() => {
    const { pageIndex, pageSize } = pagination;
    Promise.all([
      fetchProject(id as string).then((response) => {
        setProject(response.data.project);
        setLoading(false);
      }),
      fetchPrutByProject(id as string).then((response) => {
        setPrut(response.data.prut);
      }),
      fetchChangeLog(id as string, pageIndex, pageSize).then((response) => {
        setChangeLog(response.data);
      }),
      fetchPics().then((response) => {
        setPics(response.data.pics);
        setLoading(false);
      })
    ]).finally(() => {
      setReloadTable(false);
    });
  }, [id, pagination, setReloadTable]);

  useEffect(() => {
    PermissionHelper.getPermissions().then((response) => {
      setPermissions(response);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    reloadTable && fetchData();
  }, [reloadTable, fetchData]);

  return (
    <DefaultLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.page_container}>
          <div className={styles.page_header}>
            <Breadcrumb items={breadcrumbItems} />
            <div className={styles.page_header_title_container}>
              <Title level={4} className={styles.page_header_title}>
                {project.name}
              </Title>
            </div>
          </div>
          <Card
            title={<Title level={5}>{t("Project Details")}</Title>}
            extra={
              PermissionHelper.hasPermission(Permissions.EDIT_PROJECT, permissions) && (
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={onOpenEditProject}
                  disabled={isEditProjectButtonDisabled}
                >
                  Edit Project Details
                </Button>
              )
            }
          >
            <div className={styles.card_body_container}>
              <div>
                <Text className={styles.body_title_header}>
                  {t("Project Status")}:
                </Text>
                <Tag
                  color={ProjectHelper.getStatusColor(project.status)}
                  key={project.status}
                >
                  {t(ProjectHelper.getStatusTagName(project.status))}
                </Tag>
              </div>
              <div>
                <Text className={styles.body_title_header}>
                  {t("Project In-Charge")}:
                </Text>
                <Text>{project.pic.name}</Text>
              </div>
              <div className={styles.body_description}>
                <Text className={styles.body_title_header}>
                  {t("Description")}:
                </Text>
                <Text className={styles.body_description_value}>{project.description}</Text>
              </div>
            </div>
          </Card>
          <Card title={<Title level={5}>{t("Project Contributors")}</Title>}>
            <div className={styles.project_contributors_container}>
              <CurrentPrutBanner prut={prut} />
              <ProjectContributorsTable
                permissions={permissions}
                project={project}
                contributors={prut.contributors}
                setReloadTable={setReloadTable}
                addContributorDisabled={isAddContributorButtonDisabled}
              />
            </div>
          </Card>

          <Card title={<Title level={5}>{t("Change Log")}</Title>}>
            <div className={styles.project_contributors_container}>
              <ProjectChangeLog
                project={project}
                changeLog={changeLog}
                setPagination={setPagination}
              />
            </div>
          </Card>

          <UpdateProjectModal
            ref={editModalRef}
            pics={pics}
            onRefetchData={fetchData}
            permissions={permissions}
          />
        </div>
      )}
    </DefaultLayout>
  );
};

export default ProjectDetails;
