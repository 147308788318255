import { Layout, Menu } from "antd";
import React, { ReactNode } from "react";
import styles from "./DefaultLayout.module.css";
import Header from "./components/Header";
import { useDefaultLayout } from "../hooks";

const { Sider } = Layout;
interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const { selectedKey, siderItems } = useDefaultLayout();

  return (
    <Layout>
      <Header />
      <Layout>
        <Sider width={272} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={["quarterly-bonus"]}
            selectedKeys={[selectedKey]}
            className={styles.sider_menu}
            items={siderItems}
            defaultOpenKeys={["my-incentives"]}
          />
        </Sider>
        <Layout className={styles.body_container}>
          <div className={styles.body_content}>{children}</div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
