export enum Permissions {
    LIST_PROJECT = "project:list",
    EDIT_PROJECT = "project:edit",
    EDIT_ARCHIVED_PROJECT = "project:editarchived",
    ARCHIVE_PROJECT = "project:archive",
    CREATE_PROJECT = "project:create",
    PRUT_LIST = "prut:list",
    SYNC_CONTRIBUTOR = "contributor:sync",
    CREATE_BONUS = "incentives:create",
    PRUT_EXPORT = "prut:export",
    CONTRIBUTOR_ADD = "contributor:add",
    CONTRIBUTOR_LIST = "contributor:list"
}
