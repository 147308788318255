import { ProjectStatus } from "../enum/ProjectStatus";
import { Pic } from "../types/Pic";
import { DataTableHelper } from "./DataTableHelper"

const statusSorter = (a: number, b: number) => {
    return DataTableHelper.sorter(a, b);
}

const getStatusColor = (status: number) => {
    let statusColor: string;
    switch (status) {
        case ProjectStatus.ONGOING:
            statusColor = 'geekblue';
            break;
        case ProjectStatus.ONGOING_LEAD:
            statusColor = 'gold';
            break;
        case ProjectStatus.COMPLETED:
            statusColor = 'green';
            break;
        case ProjectStatus.ARCHIVED:
            statusColor = 'default';
            break;
        default:
            statusColor = 'default';
    }
    return statusColor;
}

const getStatusTagName = (status: number) => {
    let statusTagName: string;
    switch (status) {
        case ProjectStatus.ONGOING:
            statusTagName = 'Ongoing Project';
            break;
        case ProjectStatus.ONGOING_LEAD:
            statusTagName = 'Ongoing Lead';
            break;
        case ProjectStatus.COMPLETED:
            statusTagName = 'Completed';
            break;
        case ProjectStatus.ARCHIVED:
            statusTagName = 'Archived';
            break;
        default:
            statusTagName = '-';
    }
    return statusTagName;
}

const getStatusFilterOptions = () => {
    return [
        { value: ProjectStatus.ONGOING, text: getStatusTagName(ProjectStatus.ONGOING) },
        { value: ProjectStatus.ONGOING_LEAD, text: getStatusTagName(ProjectStatus.ONGOING_LEAD) },
        { value: ProjectStatus.COMPLETED, text: getStatusTagName(ProjectStatus.COMPLETED) },
        { value: ProjectStatus.ARCHIVED, text: getStatusTagName(ProjectStatus.ARCHIVED) }
    ];
}

const getPicFilterOptions = (data: Array<Pic>) => {
    return data.map(pic => {
        return { value: pic.id, text: pic.name }
    });
}

export const ProjectHelper = {
    statusSorter,
    getStatusColor,
    getStatusTagName,
    getStatusFilterOptions,
    getPicFilterOptions
}