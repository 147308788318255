import React from 'react';
import { Breadcrumb as AntDBreadcrumb, BreadcrumbProps as AntDBreadcrumbProps } from 'antd';

export interface BreadcrumbItem {
    path: string;
    name: string;
}

interface BreadcrumbProps extends AntDBreadcrumbProps {
    items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, ...props }) => {
    return (
        <AntDBreadcrumb {...props}>
            {items.map((item, index) => (
                <AntDBreadcrumb.Item key={index}>
                    { item.path !== '' 
                        ? <a href={item.path}>{item.name}</a> 
                        : item.name
                    }
                </AntDBreadcrumb.Item>
            ))}
        </AntDBreadcrumb>
    );
};

export default Breadcrumb;