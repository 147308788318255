import React, { useEffect, useCallback } from "react";
import { Typography, Image } from "antd";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../layout/DefaultLayout";
import Loader from "../components/Loader";
import styles from "./AboutIncentives.module.css";
import { ConfigHelper } from "../helpers/ConfigHelper";
import ProtectedAssetsHelper from "../helpers/ProtectedAssetsHelper";

const AboutIncentives: React.FC = () => {
    const { t } = useTranslation();
    const { Title, Text, Link } = Typography;

    const [loading, setLoading] = React.useState<boolean>(false);
    const obpURL = ConfigHelper.getConfig("REACT_APP_OBP_URL", "");
    const salesCommissionUrl = ConfigHelper.getConfig('REACT_APP_SALE_COMMISSION_URL', '');
    const riskSharePolicyUrl = ConfigHelper.getConfig('REACT_APP_RISK_SHARE_POLICY_URL', '');
    const esopPolicyUrl = ConfigHelper.getConfig('REACT_APP_ESOP_POLICY_URL', '');
    const assetHelper = new ProtectedAssetsHelper();
    const [BGImage, setBGImage] = React.useState<string>("");

    const fetchAssets = useCallback(async () => {
        try {
            setLoading(true);
            const response = await assetHelper.getUrl("about-sandp1t-incentives.png");
            setBGImage(response);
        } catch (error) {
            console.error("Error fetching assets: " + error);
        } finally {
            setLoading(false);
        }
    }, [assetHelper]);

    useEffect(() => {
        fetchAssets();
    }, []);

    return (
        <DefaultLayout>
            {loading ? <Loader /> :
                <div className={styles.page_container}>
                    <div>
                        <Title level={4}>{t('About SANDP1T Incentives')}</Title>
                    </div>
                    <div>
                        <Image
                            preview={false}
                            src={BGImage}
                            rootClassName={styles.image_container}
                        />
                    </div>
                    <div className={styles.reference_container}>
                        <Text>
                            Refer to the respective Confluence pages for more details:
                        </Text>
                        <ul className={styles.reference_list}>
                            <li>
                                <Link href={obpURL} target="_blank">Operator/Overwatch Bonus</Link>
                            </li>
                            <li>
                                <Link href={salesCommissionUrl} target="_blank">Sales Commission</Link>
                            </li>
                            <li>
                                <Link href={riskSharePolicyUrl} target="_blank">Risk-share</Link>
                            </li>
                            <li>
                                <Link href={esopPolicyUrl} target="_blank">Employee Share Option Program (ESOP)</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </DefaultLayout>
    );
};

export default AboutIncentives;
