import { useCallback, useRef } from "react";
import { AddContributorModalAction, ContributorModalInfo, ModalAction } from "../types/Contributors";
import { Project } from "../types/Project";
type Props = {
  project: Project;
};

const usePrutContributorTable = ({ project }: Props) => {
  const addContributorRef = useRef<AddContributorModalAction>(null);
  const deleteContributorRef = useRef<ModalAction<ContributorModalInfo>>(null);

  const onOpenAddContributorModal = () => {
    addContributorRef.current?.open({
      projectId: project.id,
      projectName: project.name,
    });
  };

  const onOpenContributorDeleteConfirmationModal = useCallback((data: ContributorModalInfo) => {
      deleteContributorRef.current?.open(data);
    }, []);

  return {
    addContributorRef,
    deleteContributorRef,
    onOpenAddContributorModal,
    onOpenContributorDeleteConfirmationModal,
  };
};

export default usePrutContributorTable;
