import React from "react";
import { Modal as AntDModal, ModalProps as AntDModalProps } from "antd";

interface ModalProps extends AntDModalProps {
  size?: "medium" | "large";
}

const Modal: React.FC<ModalProps> = ({ size = "medium", ...props }) => {
  const width = size === "medium" ? 800 : 1000;

  const bodyHeight = 600;
  const titleHeight = 55;
  const footerHeight = 53;
  const maxHeight =
    props.title !== undefined
      ? props.footer !== null
        ? bodyHeight - titleHeight - footerHeight
        : bodyHeight - titleHeight
      : props.footer !== null
      ? bodyHeight - footerHeight
      : bodyHeight;

  return (
    <AntDModal
      centered
      width={width}
      bodyStyle={{ maxHeight: `${maxHeight}px`, overflowY: "auto" }}
      {...props}
    />
  );
};

export default Modal;
