import React, { useEffect, useRef, useState } from "react";
import {
  ModalProps as AntDModalProps,
  Divider,
  InputNumber,
  Table,
  TableColumnsType,
  Tag,
  Typography,
  notification,
} from "antd";
import Modal from "./Modal";
import CurrentPrutBanner from "./CurrentPrutBanner";
import styles from "./PrutModal.module.css";
import { CurrentPrut, ProjectContribution, PrutByUser } from "../types/Prut";
import { DataTableHelper } from "../helpers/DataTableHelper";
import { fetchMyPrut, fetchPrutByUser, submitProjectContribution, updateMyPrut } from "../api/PrutAPI";
import { ProjectHelper } from "../helpers/ProjectHelper";
import { useTranslation } from "react-i18next";
import {
  formatPostData,
  formatTotalPercentage,
} from "../helpers/PrutHelper";
import { Pic } from "../types/Pic";
import { fetchPics } from "../api/PicAPI";
import Loader from "./Loader";

interface PrutModalProps extends AntDModalProps {
  showModal: boolean;
  upn?: string;
  onCloseModal: () => void;
  id: string;
  setReloadTable: (reloadTable: boolean) => void;
  isMyPrut?: boolean;
}

type CurrentPrutStats = {
  sumArray: number[];
  projectContribution: number;
}

const PrutModal: React.FC<PrutModalProps> = ({
  showModal,
  upn,
  onCloseModal,
  id,
  setReloadTable,
  isMyPrut = false
}) => {
  const { Text, Title } = Typography;
  const { t } = useTranslation();

  const [currentPrutStats, setCurrentPrutStats] = useState<CurrentPrutStats>({
    sumArray: [],
    projectContribution: 0,
  });

  const [enableSaveBtn, setEnableSaveBtn] = useState<boolean>(false);
  const [postData, setPostData] = useState<CurrentPrut[]>([]);
  const [currentValue, setCurrentValue] = useState<number>(0);

  const [pics, setPics] = useState<Array<Pic>>([]);
  const [prut, setPrut] = useState<PrutByUser>({
    currentWeekNumber: 1,
    currentWeekYear: 1,
    currentWeekStartDate: "",
    currentWeekEndDate: "",
    previousWeekNumber: 1,
    previousWeekYear: 1,
    projects: [],
  });
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const updatedValueRef = useRef<{ [id: string]: number }>(
    {}
  );
  
  const projectContributionColumns: TableColumnsType<ProjectContribution> = [
    {
      title: "Project Title",
      dataIndex: ["project", "name"],
      width: "19.75rem",
      ellipsis: true,
      sorter: (a, b) => DataTableHelper.stringSorter(a.project.name, b.project.name),
      render: (projectTitle: string) => <Text>{projectTitle}</Text>,
    },
    {
      title: "Project Status",
      dataIndex: ["project", "status"],
      width: "10rem",
      sorter: (a, b) => DataTableHelper.sorter(a.project.status, b.project.status),
      filters: ProjectHelper.getStatusFilterOptions(),
      onFilter: (value, record) => DataTableHelper.filter(value, record.project.status),
      render: (status: number) => {
        return (
          <Tag color={ProjectHelper.getStatusColor(status)} key={status}>
            {t(ProjectHelper.getStatusTagName(status))}
          </Tag>
        );
      },
    },
    {
      title: "Project In-Charge",
      dataIndex: ["project", "pic", "name"],
      width: "11.5rem",
      sorter: (a, b) => DataTableHelper.stringSorter(a.project.pic.name, b.project.pic.name),
      filters: ProjectHelper.getPicFilterOptions(pics),
      onFilter: (value, record) =>
        DataTableHelper.filter(value, record.project.pic.id),
    },
    {
      title: "Previous PRUT",
      dataIndex: "previous",
      width: "9.25rem",
      sorter: (a, b) => DataTableHelper.sorter(a.previous, b.previous),
      render: (previous: number) => <Text>{previous}%</Text>,
    },
    {
      title: "Current PRUT",
      dataIndex: "current",
      width: "9rem",
      sorter: (a, b) => {
        const recordA = updatedValueRef.current[a.project.id], recordB = updatedValueRef.current[b.project.id];
        return DataTableHelper.sorter(recordA ?? a.current, recordB ?? b.current)
      },
      defaultSortOrder: 'descend',
      render: (_, record) => (
        <div className={styles.current_prut_cell_container}>
          <InputNumber
            data-testid="current_value_input"
            disabled={!record.isContributorInProject}
            defaultValue={postData.find(project => project.id === record.project.id)?.current ?? record.current}
            min={0}
            max={100}
            controls={false}
            onFocus={(e) => setCurrentValue(Number(e.target.value))}
            onChange={(value) => {
              setEnableSaveBtn(true);
              setPostData(
                formatPostData(
                  [
                    {
                      id: record.project.id,
                      current: Number(value),
                    },
                  ],
                  postData
                )
              );
              setCurrentPrutStats(
                formatTotalPercentage(
                  Number(value),
                  currentPrutStats.sumArray,
                  currentValue
                )
              );
              setCurrentValue(Number(value));
              updatedValueRef.current[record.project.id] = Number(value)
            }}
            className={styles.current_prut_cell_input}
            status={currentPrutStats.projectContribution !== 100 ? "error" : ""}
          />
          %
        </div>
      ),
    },
  ];

  const handleSubmit = () => {
    setIsSavingChanges(true);
    if (!isMyPrut) {
      const data = [
        {
          id: id,
          currentWeekYear: prut.currentWeekYear,
          currentWeekNumber: prut.currentWeekNumber,
          projects: postData,
        },
      ];
      submitProjectContribution(data).then((response) => {
        if (response.status === "success") {
          notification.open({
            type: "success",
            placement: "topRight",
            top: 84,
            message: "Project utilisation saved!",
            description: `Current PRUT for ${upn} is now updated.`,
            className: styles.alert_success
          });
          setReloadTable(true);
          setPostData([]);
          updatedValueRef.current = {}
          handleClose();
          onCloseModal();
        } else {
          notification.open({
            type: "error",
            placement: "topRight",
            message: "Error!",
            description: `Error saving project utilisation for ${upn}.`,
            className: styles.alert_error,
          });
        }
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: "notification-error",
        });
      })
      .finally(() => {
        setEnableSaveBtn(false);
        setIsSavingChanges(false);
      });
    } else {
      const data = {
        currentWeekYear: prut.currentWeekYear,
        currentWeekNumber: prut.currentWeekNumber,
        projects: postData,
      };
      updateMyPrut(data).then((response) => {
        if (response.status === "success") {
          notification.open({
            type: "success",
            placement: "topRight",
            top: 84,
            message: "Project utilisation saved!",
            description: `Current PRUT is now updated.`,
            className: styles.alert_success
          });
          setReloadTable(true);
          setPostData([]);
          updatedValueRef.current = {}
          handleClose();
          onCloseModal();
        } else {
          notification.open({
            type: "error",
            placement: "topRight",
            message: "Error!",
            description: `Error saving my project utilisation.`,
            className: styles.alert_error,
          });
        }
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: "notification-error",
        });
      })
      .finally(() => {
        setEnableSaveBtn(false);
        setIsSavingChanges(false);
      });
    }
  };

  const handleCancel = () => {
    setPostData([]);
    updatedValueRef.current = {}
    setEnableSaveBtn(false);
    handleClose();
    onCloseModal();
  };

  const handleClose = () => {
    // Set everything to 0 when closing
    setCurrentPrutStats({
      sumArray: [],
      projectContribution: 0,
    });
    setPrut({
      currentWeekYear: 0,
      currentWeekNumber: 0,
      previousWeekYear: 0,
      previousWeekNumber: 0,
      currentWeekStartDate: "2024-01-01T16:00:00.000Z",
      currentWeekEndDate: "2024-01-01T15:59:59.000Z",
      projects: [],
    });
    setLoading(true);
  }

  useEffect(() => {
    fetchPics().then((response) => {
      setPics(response.data.pics);
    });
  }, [showModal]);

  useEffect(() => {
    if (isMyPrut) {
      fetchMyPrut().then((response) => {
        setPrut(response.data.prut);
        setCurrentPrutStats({
          sumArray: response.data.prut.projects.map(
            (data: { current: { current: number } }) => data.current
          ),
          projectContribution: response.data.prut.totalContribution,
        });
        setLoading(false);
      });
      return;
    }
    if (id !== "") {
      fetchPrutByUser(id).then((response) => {
        setPrut(response.data.prut);
        setCurrentPrutStats({
          sumArray: response.data.prut.projects.map(
            (data: { current: number }) => data.current
          ),
          projectContribution: response.data.prut.totalContribution,
        });
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, showModal]);

  return (
    <Modal
      afterClose={handleClose}
      title={<Title level={5}>Edit Current PRUT</Title>}
      data-testid="prut_modal"
      open={showModal}
      onOk={handleSubmit}
      onCancel={handleCancel}
      okButtonProps={{
        disabled:
          !enableSaveBtn || currentPrutStats.projectContribution !== 100,
      }}
      confirmLoading={isSavingChanges}
      okText="Save Changes"
      size={"large"}
      destroyOnClose
    >
      {loading ? <Loader className={styles.loading_container} /> : (
        <div className={styles.modal_content_container}>
          {!isMyPrut && (
            <Text>
              Please update the Current PRUT for <b>{upn}</b> below and ensure
              that the Total Current PRUT amounts to 100%.
            </Text>
          )}
          <Divider className={styles.no_margin} />
          <CurrentPrutBanner prut={prut} />
          <Table
            columns={projectContributionColumns}
            dataSource={prut.projects}
            rowKey={(record) => record.project.id}
            pagination={false}
          />
          <div className={styles.footer_container}>
            <div className={styles.footer_text}>
              <Text strong>Total Current PRUT </Text>
            </div>
            <div className={styles.total_percentage}>
              <Text data-testid="total_contribution">
                {currentPrutStats.projectContribution}{" "}
              </Text>
            </div>
            %
          </div>
          {currentPrutStats.projectContribution !== 100 && (
            <div data-testid="error_message" className={styles.message_container}>
              <Text type="danger">
                The changes cannot be saved as the Total Current PRUT does not
                amount to 100%. Please adjust the utilisation accordingly before
                attempting to save it.
              </Text>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default PrutModal;
