import { Bonus } from '../types/QuarterlyBonus';
import { get, post } from './API';

const fetchBonusSummary = async () => {
    const response = await get('/bonus/summary', {});
    return response;
};

const fetchBonusDetails = async () => {
    const response = await get('/bonus/details', {});
    return response;
};

const fetchBonus = async () => {
    const response = await get('/bonus/latest', {});
    return response;
}

const createBonus = async (body: Bonus) => {
    const response = await post("/bonus/create", body);
    return response;
  }

export { fetchBonus, fetchBonusDetails, fetchBonusSummary, createBonus };
