import { get, post, put } from './API';

const fetchProjects = async () => {
    const response = await get('/projects', {});
    return response;
}

const fetchProject = async (projectId: string) => {
    const response = await get('/project', { id: projectId });
    return response;
}

const createProject = async (body: { name: string; picId: string }) => {
  const response = await post("/project/create", body);
  return response;
}

const fetchChangeLog = async (id: string, offset: number, limit: number) => {
    const response = await get("/change-log/by-project", { id, offset, limit });
    return response;
};

const editProject = async (body: { id: string, name: string; picId: string, status: number, description?:string }) => {
    const response = await put("/project/edit", body);
    return response;
}

export { fetchProjects, fetchProject, createProject, fetchChangeLog, editProject };
