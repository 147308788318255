import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Typography,
  notification
} from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import Modal from "./Modal";
import { Bonus } from "../types/QuarterlyBonus";
import { DateHelper } from "../helpers/DateHelper";
import moment, { Moment } from "moment";
import styles from "./BonusModal.module.css";
import { createBonus, fetchBonus } from "../api/BonusAPI";
import Decimal from 'decimal.js';

interface BonusModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  bonus?: Bonus;
  onBonusSubmitted: (newBonus: Bonus) => void;
}

interface ValidateFormErrors {
  errorFields: {
    errors: string[];
    name: string[];
    warnings: string[];
  }[];
  outOfDate: boolean;
  values: Bonus;
}

type NumberType = 'ratio' | 'full';

const BonusModal: React.FC<BonusModalProps> = ({
  showModal,
  setShowModal,
  bonus,
  onBonusSubmitted,
}) => {
  const { Text, Title } = Typography;
  const [form] = useForm<Bonus>();
  const [formInstance] = useState<FormInstance>(form);
  const [payoutMonth, setPayoutMonth] = useState<Moment | null>(null);

  const RATIO_REGEX = new RegExp(/^\d+(.?\d{1,2})?$/);
  const DOLLAR_VALUE_REGEX = new RegExp(/^-?\d+(?:,\d{3})*(?:\.\d{2})?$/);
  const VALID_NUMBER_MAX_2D_REGEX = /^-?\d*(\.\d{0,2})?$/;
  const VALID_TWO_OR_MORE_DECIMAL_NUMBER_REGEX = new RegExp(/^-?\d*\.\d{2,}$/);

  const REQUIRED_ERROR = "This is a required field.";
  const VALUE_ERROR = "Please enter a valid numerical value.";

  const fieldsTo2decimal = ["totalRevenue", "totalCollection", "overallProjectBudget", "overallCostOfRevenue", "totalForecastedQuarterlyBonus", "totalPayableQuarterlyBonus", "quarterlyBonusPerPerson"];

  const handleSubmit = async () => {
    try {
      const formValues = await formInstance?.validateFields();

      await createBonus(formValues)
        .then(async () => {
          const latestBonus = await fetchBonus();
          onBonusSubmitted(latestBonus);

          notification.open({
            type: "success",
            placement: "topRight",
            top: 84,
            message: "Quarterly bonus published!",
            description: `The updated quarterly bonus details was successfully published to all consumers.`,
            className: styles.alert_success,
          });
          handleClose();
        })
        .catch((_error) => {
          notification.open({
            type: "error",
            placement: "topRight",
            top: 84,
            message: "Action Failed",
            description:
              "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists",
            className: styles.alert_error,
          });
        })
    } catch (error) {
      const { errorFields } = error as ValidateFormErrors;
      document.getElementById("bonus_" + errorFields[0].name[0])?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCancel = () => {
    formInstance?.resetFields();
    setPayoutMonth(bonus?.payoutMonth ? moment(bonus.payoutMonth, "MMM YYYY") : null);
    handleClose();
  };

  const handleClose = () => {
    document.getElementsByClassName("ant-modal-body")[0].scrollTop = 0;
    setShowModal(false);
  }

  const handleMonthPickerChange = (moment: Moment | null, dateString: string) => {
    form.setFieldValue("payoutMonth", dateString);
    setPayoutMonth(moment);
  }

  const handleFieldsChange = (changedField: any, allFields: any) => {
    if (changedField.length > 1) return;

    let fieldToValidate = { ...changedField[0] };

    if (fieldToValidate.errors.length > 0) {
      fieldToValidate = { ...fieldToValidate, errors: [] };
      form.setFields([...allFields, fieldToValidate])
    }
  }

  const handleFieldsBlur = (event: React.FocusEvent<HTMLFormElement>) => {
    const [, name] = event.target.id.split("_");
    const fieldValue: string = form.getFieldValue(name);

    if (fieldValue && fieldsTo2decimal.includes(name) && VALID_NUMBER_MAX_2D_REGEX.test(fieldValue)) {
      const format2decimal = new Decimal(fieldValue).toFixed(2);
      // Format the number with commas as thousands separators
      const formatWithCommas = format2decimal.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      form.setFieldsValue({ [name]: formatWithCommas });
    }
  }

  const handleFieldsOnFocus = (event: React.FocusEvent<HTMLFormElement>) => {
    const [, fieldName] = event.target.id.split("_");
    const fieldValue: string = form.getFieldValue(fieldName);

    if (fieldValue && fieldsTo2decimal.includes(fieldName)) {
      // Remove any non-numeric characters except for the decimal point and minus sign
      const onlyNumber = fieldValue.replace(/[^0-9.-]/g, "");
      form.setFieldsValue({ [fieldName]: onlyNumber });
    }
  }

  const handleNumberInputChange = (type: NumberType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    switch (type) {
      case 'ratio':
        value = value.replace(/[^0-9.]/g, ''); // Only allow positive numbers and decimal points
        break;
      case 'full':
        value = value.replace(/[^0-9.-]/g, ''); // number with negative and decimal points
        break;
    }

    if (VALID_TWO_OR_MORE_DECIMAL_NUMBER_REGEX.test(value)) {
      //round down to 2 decimal places
      let numValue = new Decimal(value).toDecimalPlaces(2, Decimal.ROUND_DOWN);
      value = numValue.toFixed(2);
    }
    event.target.value = value;
  };

  useEffect(() => {
    if (!bonus) return;

    form.setFieldsValue(bonus);
    bonus.payoutMonth && setPayoutMonth(moment(bonus.payoutMonth, "MMM YYYY"));
  }, [bonus, form]);

  return (
    <Modal
      afterClose={() => {
        handleClose();
      }}
      title={<Title level={5}>Update Quarterly Bonus</Title>}
      data-testid="bonus_modal"
      open={showModal}
      onOk={handleSubmit}
      onCancel={() => {
        handleCancel();
      }}
      okText="Publish"
      okButtonProps={{ htmlType: "submit" }}
      size={"large"}
      destroyOnClose
      forceRender
    >
      <Form name="bonus"
        labelCol={{ style: { width: "15rem" } }}
        form={form}
        validateTrigger="onSubmit"
        onFieldsChange={handleFieldsChange}
        onBlur={handleFieldsBlur}
        onFocus={handleFieldsOnFocus}
      >
        <Text className={styles.section_title} id="modal_body_title">Quarterly Bonus Details</Text>

        <Form.Item
          label="Quarterly Bonus Period"
          name="bonusPeriod"
          rules={[{ required: true, message: REQUIRED_ERROR }]}
          className={styles.top_form_item}
        >
          <Select options={DateHelper.generatePayoutPeriods()} placeholder="Select Quarterly Bonus Period" />
        </Form.Item>

        <Form.Item
          label="Payout Month"
          name="payoutMonth"
          valuePropName="date"
          rules={[{ required: true, message: REQUIRED_ERROR }]}
          className={styles.btm_form_item}
        >
          <DatePicker
            picker="month"
            placeholder="Select Payout Month"
            className={styles.datepicker_field}
            value={payoutMonth}
            onChange={handleMonthPickerChange}
            format="MMM YYYY"
          />
        </Form.Item>

        <Divider className={styles.modal_divider} />

        <Text className={styles.section_title}>Quarterly Bonus Full Breakdown</Text>

        <Form.Item
          className={styles.top_form_item}
          label="Total Revenue"
          name="totalRevenue"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: DOLLAR_VALUE_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonBefore="$" placeholder="Enter Total Revenue" onInput={handleNumberInputChange('full')} />
        </Form.Item>

        <Form.Item
          label="Total Collection"
          name="totalCollection"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: DOLLAR_VALUE_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonBefore="$" placeholder="Enter Total Collection" onInput={handleNumberInputChange('full')} />
        </Form.Item>

        <Form.Item
          label="Collection Ratio"
          name="collectionRatio"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: RATIO_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input placeholder="Enter Collection Ratio" onInput={handleNumberInputChange('ratio')} />
        </Form.Item>

        <Form.Item
          label="Overall Project Budget"
          name="overallProjectBudget"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: DOLLAR_VALUE_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonBefore="$" placeholder="Enter Overall Project Budget" onInput={handleNumberInputChange('full')} />
        </Form.Item>

        <Form.Item
          label="Overall Cost of Revenue"
          name="overallCostOfRevenue"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: DOLLAR_VALUE_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonBefore="$" placeholder="Enter Overall Cost of Revenue" onInput={handleNumberInputChange('full')} />
        </Form.Item>

        <Form.Item
          label="Total Forecasted Quarterly Bonus"
          name="totalForecastedQuarterlyBonus"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: DOLLAR_VALUE_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonBefore="$" placeholder="Enter Total Forecasted Quarterly Bonus" onInput={handleNumberInputChange('full')} />
        </Form.Item>

        <Form.Item
          label="Total Payable Quarterly Bonus"
          name="totalPayableQuarterlyBonus"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: DOLLAR_VALUE_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonBefore="$" placeholder="Enter Total Payable Quarterly Bonus" onInput={handleNumberInputChange('full')} />
        </Form.Item>

        <Form.Item
          label="No. of Eligible Persons"
          name="totalEligiblePersons"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: RATIO_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonAfter="pax" placeholder="Enter No. of Eligible Persons" onInput={handleNumberInputChange('ratio')} />
        </Form.Item>

        <Form.Item
          label="Quarterly Bonus Per Person"
          name="quarterlyBonusPerPerson"
          rules={[
            { required: true, message: REQUIRED_ERROR },
            { pattern: DOLLAR_VALUE_REGEX, message: VALUE_ERROR }
          ]}
        >
          <Input addonBefore="$" placeholder="Enter Quarterly Bonus Per Person" onInput={handleNumberInputChange('full')} />
        </Form.Item>

        <Form.Item
          label="Remarks"
          name="remarks"
        >
          <Input.TextArea
            placeholder="Enter any remarks to be published"
            showCount
            maxLength={2000}
            rows={3}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BonusModal;
