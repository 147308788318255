import React from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import ErrorPageLayout from '../layout/ErrorPageLayout';
import { CloseCircleFilled, WarningFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface ErrorProps {
  errorType: 'oops' | 'not-found' | 'unauthorised' | string;
}

const Error: React.FC<ErrorProps> = ({ errorType }) => {
  const { t } = useTranslation();

  let title, subTitle, icon;

  switch (errorType) {
    case 'oops':
      title = "Oops! Something Went Wrong";
      subTitle = "We're sorry, but it seems like there's a hiccup in the system.\n Don't worry, our team of tech gurus has been notified and is on the case.";
      icon=<CloseCircleFilled style={{ color: '#ff4d4f', fontSize: '70px' }} />;
      break;
    case 'not-found':
      title = "Page Not Found";
      subTitle = "We're sorry, the requested page cannot be found.\nPlease try a different URL or return to the homepage.";
      icon = <WarningFilled style={{ color: '#ff4d4f', fontSize: '70px' }} />;
      break;
    case 'unauthorised':
      title = "Unauthorised Access";
      subTitle = "We're sorry, you do not have permission to access the requested page.";
      icon = <WarningFilled style={{ color: '#ff4d4f', fontSize: '70px' }} />;
      break;
    default:
      title = "Page Not Found";
      subTitle = "We're sorry, the requested page cannot be found.\nPlease try a different URL or return to the homepage.";
      icon = <WarningFilled style={{ color: '#ff4d4f', fontSize: '70px' }} />;
      break;
  }

  return (
    <ErrorPageLayout>
        <Result
          icon={icon}
          title={title}
          subTitle={subTitle.split('\n').map((item, i) => (
            <span key={`${i}-${item}`} style={{'display': 'block'}}>{item}</span>
          ))}
          extra={
              <Button type="primary">
                <Link to="/">{t('Back To Home')}</Link>
              </Button>
          }
        />
    </ErrorPageLayout>
  );
};

export default Error;